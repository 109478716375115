import { Stepper, Step, StepLabel } from '@mui/material'

import { REPORT_STEPS } from './constants/general'

export const MINStepper = ({ activeStep = 0 }) => {
    return (
        <>
            <Stepper activeStep={activeStep}>
                {Object.keys(REPORT_STEPS).map(step => {
                    return (
                        <Step key={step}>
                            <StepLabel>{REPORT_STEPS[step].label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </>
    )
}
