import React, { useEffect } from 'react'

import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

import { Figure, Image, CloudinaryImage, ImagePending } from './Figure'

export const VerticalGallery = ({ images, cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME }) => {
    // Set up Photoswipe Lightbox
    const galleryId = 'images'
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + galleryId,
            children: 'a',
            pswpModule: () => import('photoswipe'),
        })
        lightbox.on('uiRegister', function () {
            lightbox.pswp.ui.registerElement({
                name: 'custom-caption',
                order: 9,
                isButton: false,
                appendTo: 'root',
                html: 'Caption text',
                onInit: (el, pswp) => {
                    lightbox.pswp.on('change', () => {
                        const currSlideElement = lightbox.pswp.currSlide.data.element
                        let captionHTML = ''
                        if (currSlideElement) {
                            captionHTML = currSlideElement.querySelector('img').getAttribute('alt')
                        }
                        el.innerHTML = captionHTML || ''
                        if (!captionHTML) {
                            el.classList.add('no-caption')
                        } else {
                            el.classList.remove('no-caption')
                        }
                    })
                },
            })
        })
        lightbox.init()

        return () => {
            lightbox.destroy()
            lightbox = null
        }
    }, [])

    return (
        <div className="pswp-gallery" id={galleryId}>
            <ImageList cols={1} sx={styles.list}>
                {images.map(image => {
                    return (
                        <ImageListItem key={image.id}>
                            <Figure>
                                {image.url.startsWith('https://res.cloudinary.com/') ? (
                                    <CloudinaryImage
                                        url={image.url}
                                        width={image.width}
                                        height={image.height}
                                        alt={image.caption}
                                        cloudName={cloudName}
                                    />
                                ) : (
                                    <ImagePending>
                                        <Image {...image} alt={image.caption} sizes={SIZES} />
                                    </ImagePending>
                                )}
                            </Figure>
                            {(image.caption || image.credit) && (
                                <ImageListItemBar title={image.caption} subtitle={image.credit} />
                            )}
                        </ImageListItem>
                    )
                })}
            </ImageList>
        </div>
    )
}

// Constants and utils

const SIZES = ['100%']
const styles = {
    list: {
        margin: 0,
    },
}
