import { useReport } from './Context'

export const Confidence = () => {
    const { confidence } = useReport()

    if (!confidence) return null

    const { statements } = confidence

    return (
        <>
            {Array.isArray(statements) ? (
                <ul>
                    {statements.map((statement, index) => (
                        <li key={index}>{statement}</li>
                    ))}
                </ul>
            ) : null}
        </>
    )
}
