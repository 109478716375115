import React from 'react'

import Shim from 'components/Shim/Shim'

export const SubHeader = ({ children, shimHorizontal = true }) => {
    return shimHorizontal ? (
        <Shim horizontal>
            <h2 style={style.heading}>{children}</h2>
        </Shim>
    ) : (
        <h2 style={style.heading}>{children}</h2>
    )
}

const style = {
    heading: {
        margin: '0.25rem 0',
        lineHeight: 1.2,
        fontSize: '1.2rem',
    },
}
