import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Expand from 'components/button/Expand'
import { useToggle } from 'hooks'
import { noop } from '@avalanche-canada/utils/function'
import { WHITE } from 'constants/colors'
import css from './Tabs.module.css'

const COMPACT = 'COMPACT'
const LOOSE = 'LOOSE'

Tabs.propTypes = {
    children: PropTypes.element.isRequired,
    theme: PropTypes.oneOf([LOOSE, COMPACT]),
    activeTab: PropTypes.number,
    onTabChange: PropTypes.func,
    eager: PropTypes.bool,
}

export default function Tabs({ onTabChange, activeTab, children, theme, eager }) {
    return (
        <Provider activeTab={activeTab} onTabChange={onTabChange} theme={theme} eager={eager}>
            <div className={css.Tabs}>{children}</div>
        </Provider>
    )
}

export { Tabs as Container }

PanelSet.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
}

export function PanelSet({ children }) {
    return React.Children.map(children, (panel, index) => React.cloneElement(panel, { index }))
}

Panel.propTypes = {
    index: PropTypes.number,
    children: PropTypes.element,
    style: PropTypes.object,
}

export function Panel({ index, children, style }) {
    const eager = useEager()
    const active = useActive()
    const isActive = active.index === index
    const props = {
        role: 'tabpanel',
        className: css.Panel,
        style,
    }

    if (eager === true) {
        props.hidden = !isActive
    } else if (!isActive) {
        return null
    }

    return <div {...props}>{children}</div>
}

HeaderSet.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    stacked: PropTypes.bool,
}

export function HeaderSet({ stacked, children }) {
    const theme = useTheme()
    const [expanded, toggleExpanded] = useToggle(false)
    const className = clsx({
        [css.HeaderSet]: true,
        [css.HeaderSetLoose]: theme === LOOSE,
        [css.HeaderSetCompact]: theme === COMPACT,
        [css.HeaderSetStacked]: stacked,
        [css.HeaderSetExpanded]: expanded,
    })

    return (
        <div className={className} onClick={stacked ? toggleExpanded : undefined}>
            {React.Children.map(children, (header, index) => React.cloneElement(header, { index }))}
            {stacked && <Expand chevron expanded={expanded} color={WHITE} />}
        </div>
    )
}

Header.propTypes = {
    index: PropTypes.number,
    disabled: PropTypes.bool,
    arrow: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
}

export function Header({ index, disabled, arrow, children, style }) {
    const active = useActive()
    const className = clsx({
        [css.Header]: true,
        [css.HeaderArrow]: arrow,
        [css.HeaderisActive]: active.index === index,
        [css.HeaderDisabled]: disabled,
    })
    const handleClick = React.useCallback(() => {
        active.set(index)
    }, [index, active])

    return (
        <div role="tab" className={className} style={style} onClick={handleClick}>
            {children}
        </div>
    )
}

ColoredHeader.propTypes = {
    index: PropTypes.number,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    arrow: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
}

export function ColoredHeader({ color, ...props }) {
    const { index, disabled } = props
    const active = useActive()
    const style = React.useMemo(() => {
        if (!color) {
            return null
        }

        const isActive = active.index === index

        return {
            backgroundColor: disabled ? null : isActive ? color : color,
            color: disabled ? 'var(--color-step-350)' : 'white',
        }
    }, [color, index, active.index, disabled])

    return <Header {...props} style={style} />
}

// Utils
const context = React.createContext()

function Provider({ children, activeTab, onTabChange = noop, theme = COMPACT, eager = false }) {
    const [active, setActiveTab] = React.useState(activeTab || 0)
    const value = React.useMemo(() => {
        return {
            eager,
            theme,
            active: {
                index: active,
                set(index) {
                    setActiveTab(() => {
                        onTabChange(index)

                        return index
                    })
                },
            },
        }
    }, [theme, active, onTabChange, eager])

    React.useEffect(() => {
        if (typeof activeTab === 'number') {
            setActiveTab(activeTab)
        }
    }, [activeTab])

    return <context.Provider value={value}>{children}</context.Provider>
}

function useActive() {
    const { active } = useContext()

    return active
}

function useTheme() {
    const { theme } = useContext()

    return theme
}

function useEager() {
    const { eager } = useContext()

    return eager
}

function useContext() {
    return React.useContext(context)
}
