import PropTypes from 'prop-types'
import { has } from 'lodash'
import * as Context from '../Context'
import styles from '../MountainInformationNetwork.module.css'
import Value from './Value'

BaseSection.propTypes = {
    header: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default function BaseSection({ header, children }) {
    return (
        <section className={styles.Section}>
            <h4 className={styles['Section--Title']}>{header}</h4>
            <div className={styles['Section--Content']}>{children}</div>
        </section>
    )
}

export function Section({ header, content }) {
    const report = Context.useReport()

    if (content.some(item => has(report, item.path))) {
        return (
            <BaseSection header={header}>
                {content.map(item => (
                    <Content key={item.path} {...item} />
                ))}
            </BaseSection>
        )
    }

    return null
}

function Content({ path, description }) {
    const value = Context.useReportValue(path)

    return <Value value={value} description={description} />
}
