import { Box, Grid, List, ListItemButton, ListItem as ListItemMUI, ListItemText } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import Shim from 'components/Shim/Shim'
import { Back } from 'components/button/Back'
import messages from 'services/intl/messageDefinitions'

import { useFilteredIceClimbs } from 'hooks/ice-climbs'

export const IceClimbTable = ({ select }) => {
    const { climbs } = useFilteredIceClimbs()

    return (
        <>
            <Shim horizontal>
                <Box sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    <Back />
                    <div>
                        <FormattedMessage {...messages.climbsCount} values={{ count: climbs.length }} />
                    </div>
                </Box>
            </Shim>
            <DetailsWrapper>
                <List>
                    {climbs.length === 0 ? (
                        <ListItemMUI>
                            <FormattedMessage {...messages.listViewNoIceClimbs} />
                        </ListItemMUI>
                    ) : (
                        climbs.map(item => {
                            const { id, name } = item.properties
                            return (
                                <ListItemMUI
                                    key={id}
                                    disablePadding
                                    onClick={() => {
                                        select(id)
                                    }}
                                    sx={style.listItem}
                                >
                                    <ListItemButton>
                                        <ListItemText component="div" primary={<PrimaryText title={name} />} />
                                    </ListItemButton>
                                </ListItemMUI>
                            )
                        })
                    )}
                </List>
            </DetailsWrapper>
        </>
    )
}

const PrimaryText = ({ title }) => {
    return <Grid sx={style.truncateText}>{title}</Grid>
}

const style = {
    headerSection: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
    iconFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    listItem: {
        borderTop: '1px solid var(--color-step-150)',
    },
    truncateText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: '0.3em',
    },
    date: {
        marginLeft: '0.3em',
    },
}
