import { FormattedMessage } from 'react-intl'

// Avalanche Section
export const AVALANCHE_DATE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Avalanche date/time" />,
    path: 'observations.avalanche.avalancheOccurrenceDatetime',
    required: true,
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="If you triggered or witnessed an avalanche add date/time."
            />
        ),
    },
}

export const AVALANCHE_OCCURRENCE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Estimated occurrence time of avalanche" />,
    path: 'observations.avalanche.avalancheObservation',
    options: [
        {
            key: '12hrsAgo',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Less than 12 hours ago" />,
        },
        {
            key: '12to24hrsAgo',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="12 to 24 hours ago" />,
        },
        {
            key: '24to48hrsAgo',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="24 to 48 hours ago" />,
        },
        {
            key: 'moreThan48hrsAgo',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="More than 24 hours ago" />,
        },
    ],
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="If you observed evidence of recent avalanches, estimate occurrence time."
            />
        ),
    },
}

export const NUMBER_OF_AVALANCHES = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Number of avalanches in this report" />,
    path: 'observations.avalanche.numberOfAvalanches',
    options: [
        { key: '1', label: '1' },
        { key: '2-5', label: '2-5' },
        { key: '6-10', label: '6-10' },
        { key: '11-50', label: '11-50' },
        { key: '51-100', label: '51-100' },
    ],
}

export const AVALANCHE_SIZE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="The size of the avalanche" />,
    path: 'observations.avalanche.avalancheSize',
    options: [
        { key: '1', label: '1' },
        { key: '1.5', label: '1.5' },
        { key: '2', label: '2' },
        { key: '2.5', label: '2.5' },
        { key: '3', label: '3' },
        { key: '3.5', label: '3.5' },
        { key: '4', label: '4' },
        { key: '4.5', label: '4.5' },
        { key: '5', label: '5' },
    ],
    description: {
        title: <FormattedMessage description="MIN Form Field" defaultMessage="Canadian size classification" />,
        type: 'bullets',
        info: [
            <FormattedMessage description="MIN Form Field" defaultMessage="Size 1 is relatively harmless to people." />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Size 2 can bury, injure or kill a person."
            />,
            <FormattedMessage description="MIN Form Field" defaultMessage="Size 3 can bury and destroy a car." />,
            <FormattedMessage description="MIN Form Field" defaultMessage="Size 4 can destroy a railway car." />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Size 5 can destroy 40 hectares of forest."
            />,
        ],
    },
}

export const SLAB_THICKNESS = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Slab thickness (cm)" />,
    path: 'observations.avalanche.slabThickness',
    min: 10,
    max: 500,
}

export const SLAB_WIDTH = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Slab width (m)" />,
    path: 'observations.avalanche.slabWidth',
    min: 1,
    max: 3000,
}

export const RUN_LENGTH = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Run length (m)" />,
    path: 'observations.avalanche.runLength',
    description: {
        info: <FormattedMessage description="MIN Form Field" defaultMessage="Length from crown to toe of debris." />,
    },
    min: 1,
    max: 10000,
}

export const AVALANCHE_CHARACTERISTICS = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Avalanche character" />,
    path: 'observations.avalanche.avalancheCharacter',
    options: [
        {
            key: 'windSlab',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Wind slab" />,
        },
        {
            key: 'persistentSlab',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Persistent slab" />,
        },
        {
            key: 'deepPersistentSlab',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Deep persistent slab" />,
        },
        {
            key: 'wetSlab',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Wet slab" />,
        },
        {
            key: 'corniceOnly',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cornice only" />,
        },
        {
            key: 'corniceWithSlab',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cornice with slab" />,
        },
        {
            key: 'looseWet',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Loose wet" />,
        },
        {
            key: 'looseDry',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Loose dry" />,
        },
        {
            key: 'stormSlab',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Storm slab" />,
        },
    ],
}

export const TRIGGER_TYPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Trigger type" />,
    path: 'observations.avalanche.triggerType',
    required: true,
    options: [
        {
            key: 'natural',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Natural" />,
        },
        {
            key: 'skier',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Skier" />,
        },
        {
            key: 'snowmobile',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowmobile" />,
        },
        {
            key: 'otherVehicle',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Other vehicle" />,
        },
        {
            key: 'helicopter',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Helicopter" />,
        },
        {
            key: 'explosives',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Explosives" />,
        },
    ],
}

export const TRIGGER_SUB_TYPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Trigger subtype" />,
    path: 'observations.avalanche.triggerSubType',
    options: [
        {
            key: 'accidental',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Accidental" />,
        },
        {
            key: 'intentional',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Intentional" />,
        },
        {
            key: 'remote',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Remote" />,
        },
    ],
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="A remote trigger is when the avalanche starts some distance away from where the trigger was applied."
            />
        ),
    },
}

export const TRIGGER_DISTANCE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Remote trigger distance (m)" />,
    path: 'observations.avalanche.triggerDistance',
    min: 0,
    max: 2000,
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="If a remote trigger, enter how far from the trigger point is the nearest part of the crown."
            />
        ),
    },
}

export const START_ZONE_ASPECT = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Start zone aspect" />,
    path: 'observations.avalanche.startZoneAspect',
    options: [
        { key: 'N', label: <FormattedMessage description="MIN Form Field" defaultMessage="N" /> },
        { key: 'NE', label: <FormattedMessage description="MIN Form Field" defaultMessage="NE" /> },
        { key: 'E', label: <FormattedMessage description="MIN Form Field" defaultMessage="E" /> },
        { key: 'S', label: <FormattedMessage description="MIN Form Field" defaultMessage="S" /> },
        { key: 'SE', label: <FormattedMessage description="MIN Form Field" defaultMessage="SE" /> },
        { key: 'SW', label: <FormattedMessage description="MIN Form Field" defaultMessage="SW" /> },
        { key: 'W', label: <FormattedMessage description="MIN Form Field" defaultMessage="W" /> },
        { key: 'NW', label: <FormattedMessage description="MIN Form Field" defaultMessage="NW" /> },
    ],
}

export const START_ZONE_ELEVATION_BAND = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Start zone elevation band" />,
    path: 'observations.avalanche.startZoneElevationBand',
    progress: 5.5,
    options: [
        {
            key: 'alp',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Alpine" />,
        },
        {
            key: 'tln',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Treeline" />,
        },
        {
            key: 'btl',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Below treeline" />,
        },
    ],
}

export const START_ZONE_ELEVATION = {
    label: 'Start zone elevation (m)',
    path: 'observations.avalanche.startZoneElevation',
    progress: 5.5,
    min: 0,
    max: 8848,
}

export const START_ZONE_INCLINE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Start zone incline (degrees)" />,
    path: 'observations.avalanche.startZoneIncline',
    progress: 5.5,
    min: 0,
    max: 90,
}

export const RUNOUT_ZONE_ELEVATION = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Runout zone elevation (m)" />,
    path: 'observations.avalanche.runoutZoneElevation',
    progress: 5.5,
    min: 0,
    max: 8848,
    description: {
        info: <FormattedMessage description="MIN Form Field" defaultMessage="The lowest point of the debris." />,
    },
}

export const WEAK_LAYER_BURIAL_DATE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Weak layer burial date" />,
    path: 'observations.avalanche.weakLayerBurialDate',
    progress: 5.5,
    description: {
        info: <FormattedMessage description="MIN Form Field" defaultMessage="Date the weak layer was buried." />,
    },
}

export const WEAK_LAYER_CRYSTAL_TYPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Weak layer crystal type" />,
    path: 'observations.avalanche.weakLayerCrystalType',
    progress: 5.5,
    options: [
        {
            key: 'surfaceHoar',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Surface hoar" />,
        },
        {
            key: 'facets',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Facets" />,
        },
        {
            key: 'surfaceHoarAndFacets',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Surface hoar and facets" />,
        },
        {
            key: 'depthHoar',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Depth hoar" />,
        },
        {
            key: 'stormSnow',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Storm snow" />,
        },
    ],
}

export const CRUST_NEAR_WEAK_LAYER = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Crust near weak layer" />,
    path: 'observations.avalanche.crustNearWeakLayer',
    progress: 5.5,
    options: [
        {
            key: true,
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Yes" />,
        }, // will need to convert to booleans
        {
            key: false,
            label: <FormattedMessage description="MIN Form Field" defaultMessage="No" />,
        },
    ],
}

export const WIND_EXPOSURE = {
    label: 'Wind exposure',
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Wind exposure" />,
    path: 'observations.avalanche.windExposure',
    progress: 5.5,
    options: [
        {
            key: 'leeSlope',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Lee slope" />,
        },
        {
            key: 'windwardSlope',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Windward slope" />,
        },
        {
            key: 'crossLoadedSlope',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cross-loaded slope" />,
        },
        {
            key: 'downFlow',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Down flow" />,
        },
        {
            key: 'reverseLoadedSlope',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Reverse-loaded slope" />,
        },
        {
            key: 'noWindExposure',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="No wind exposure" />,
        },
    ],
}
export const VEGETATION_COVER = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Vegetation cover" />,
    path: 'observations.avalanche.vegetationCover',
    progress: 5.5,
    options: [
        {
            key: 'openSlope',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Open slope" />,
        },
        {
            key: 'sparseTrees',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Sparse trees" />,
        },
        {
            key: 'denseTrees',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Dense trees" />,
        },
    ],
}

export const COMMENT = {
    path: 'observations.avalanche.comment',
}

export const AVALANCHE_FIELDS = [
    AVALANCHE_DATE,
    AVALANCHE_OCCURRENCE,
    NUMBER_OF_AVALANCHES,
    AVALANCHE_SIZE,
    SLAB_THICKNESS,
    SLAB_WIDTH,
    RUN_LENGTH,
    AVALANCHE_CHARACTERISTICS,
    TRIGGER_TYPE,
    TRIGGER_SUB_TYPE,
    TRIGGER_DISTANCE,
    START_ZONE_ASPECT,
    START_ZONE_ELEVATION_BAND,
    START_ZONE_ELEVATION,
    START_ZONE_INCLINE,
    RUNOUT_ZONE_ELEVATION,
    WEAK_LAYER_BURIAL_DATE,
    WEAK_LAYER_CRYSTAL_TYPE,
    CRUST_NEAR_WEAK_LAYER,
    WIND_EXPOSURE,
    VEGETATION_COVER,
    COMMENT,
]
