import PropTypes from 'prop-types'
import * as Context from './Context'
import * as Components from 'components/metadata'
import Value from './sections/Value'
import Tags from './Tags'

export default function MountainInformationNetworkMetadata() {
    const schema = Context.useSchema()
    const { content } = schema.content.find(item => item.widget === 'metadata')

    return (
        <Components.Metadata>
            {content.map(item => (
                <Entry key={item.path} {...item} />
            ))}
        </Components.Metadata>
    )
}

Entry.propTypes = {
    path: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    description: PropTypes.shape({
        widget: PropTypes.oneOf(['geolocation', 'tags']),
        format: PropTypes.oneOf(['date', 'date-time']),
        unit: PropTypes.oneOf(['centimeter', 'meter']),
        options: PropTypes.object,
    }),
}

function Entry(props) {
    const { path, term, description = {} } = props
    const { widget, ...rest } = description
    const value = Context.useReportValue(path)

    if (Array.isArray(value) && value.length == 0) {
        return null
    }

    if (WidgetComponents.has(widget)) {
        const WidgetComponent = WidgetComponents.get(widget)

        return <WidgetComponent term={term} value={value} {...rest} />
    }

    return (
        <Components.Entry term={term}>
            <Value value={value} description={description} />
        </Components.Entry>
    )
}

const WidgetComponents = new Map([
    ['tags', TagsWidget],
    ['geolocation', GeoLocationWidget],
])

function TagsWidget({ value, term, options }) {
    return (
        <Components.Entry term={term}>
            <Tags value={value} options={options} />
        </Components.Entry>
    )
}

function GeoLocationWidget({ value, term }) {
    // Draft MINs might not have a location so we need to check for it
    const longitude = value && value.longitude ? value.longitude : undefined
    const latitude = value && value.latitude ? value.latitude : undefined

    return <Components.LocationEntry term={term} longitude={longitude} latitude={latitude} />
}
