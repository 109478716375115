import { useEffect } from 'react'

import { ImageList, Grid, Button } from '@mui/material'
import Image from 'next/image'
import { FormattedMessage } from 'react-intl'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import { BsFillCameraFill } from 'react-icons/bs'
import ReactHtmlParser from 'react-html-parser'

import { SPAW } from '@avalanche-canada/constants/products'

import { useSpaws } from 'hooks/data/SPAW'
import { Danger } from 'components/alert'
import { Tag as BaseTag } from 'components/tag'
import { SpawImage } from './spawImage'
import Shim from 'components/Shim/Shim'
import { InnerHTML } from 'components/misc/InnerHTML'
import { useSetExplorerView, useSetLayout, useSetProductID } from 'stores/ViewStore'
import { replaceAnchorTagsRecursive } from 'utils/url'
import { EXPLORER_LAYOUT } from 'constants/layout'

import styles from './spaw.module.css'

export const SpawAlert = ({ setProduct }) => {
    const { data, error } = useSpaws()

    if (!data || error) {
        return false
    }

    return (
        <Danger>
            <Button onClick={() => setProduct(SPAW, null)} style={stylesObj.buttonLink}>
                <FormattedMessage defaultMessage="Special Public Avalanche Warning" description="SPAW banner" />
            </Button>
        </Danger>
    )
}

export function Page({ spaw }) {
    const setLayout = useSetLayout()
    const setExplorerView = useSetExplorerView()
    const setProductID = useSetProductID()

    // Set up Photoswipe Lightbox
    const galleryId = 'spaws'
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + galleryId,
            children: 'a',
            pswpModule: () => import('photoswipe'),
        })
        lightbox.init()

        return () => {
            lightbox.destroy()
            lightbox = null
        }
    }, [])

    const images = spaw.media.Images
    const generalImages = images.filter(image => image.tag === 'general')
        ? images.filter(image => image.tag === 'general')
        : []
    const avalancheImages = images.filter(image => image.tag === 'avalanche')
        ? images.filter(image => image.tag === 'avalanche')
        : []

    const handleOnClick = (id, type) => {
        setLayout(EXPLORER_LAYOUT)
        setExplorerView(type)
        setProductID(id)
    }

    const jsx = ReactHtmlParser(spaw.message)

    // replace the anchor tags using the recursive function
    jsx.forEach(element => {
        replaceAnchorTagsRecursive(element, handleOnClick)
    })

    return (
        <>
            <Header title={<Title spaw={spaw} />} />
            <main>
                {spaw.communications?.headline ? (
                    <header className={styles.Subheadline}>
                        <InnerHTML>{spaw.communications.headline}</InnerHTML>
                    </header>
                ) : null}
                {generalImages.map(image => (
                    <Image
                        src={image.url}
                        alt={image.tag}
                        width={image.width}
                        height={image.height}
                        loading="lazy"
                        quality={'auto'}
                        sizes="100vw"
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                    />
                ))}
                {spaw.message && <div className={styles.Content}>{jsx}</div>}
                <div className="pswp-gallery" id={galleryId}>
                    <ImageList cols={1}>
                        {avalancheImages.map(image => (
                            <div style={stylesObj.imageList}>
                                <SpawImage key={image.id} image={image} galleryId={galleryId} />
                                <ImageBanner caption={image.caption} credit={image.credit} />
                            </div>
                        ))}
                    </ImageList>
                </div>
            </main>
        </>
    )
}

export function NoActiveSpaw() {
    return (
        <>
            <Header title={<Title />} />
            <main>
                <div className={styles.Content}>
                    <p>
                        <FormattedMessage
                            description="Layout SPAW"
                            defaultMessage="There is currently no Special Public Avalanche Warning in effect."
                        />
                    </p>
                </div>
            </main>
        </>
    )
}

export function SpawNotFound() {
    return (
        <>
            <Header title={<Title />} />
            <main>
                <div className={styles.Content}>
                    <p>
                        <FormattedMessage
                            description="Layout SPAW"
                            defaultMessage="The Special Public Avalanche Warning you are looking for does not exist."
                        />
                    </p>
                </div>
            </main>
        </>
    )
}

const Header = ({ title }) => {
    return <header className={styles.Headline}>{title}</header>
}

const Title = ({ spaw = null }) => {
    return (
        <Shim horizontal>
            <h2>
                {spaw ? (
                    Date.now() > spaw.expiryDateTime ? (
                        <BaseTag as={'strong'} className={styles.TagExpired}>
                            <FormattedMessage description="expired" defaultMessage="Expired" />
                        </BaseTag>
                    ) : (
                        <BaseTag as={'strong'} className={styles.TagActive}>
                            <FormattedMessage description="in-effect" defaultMessage="In effect" />
                        </BaseTag>
                    )
                ) : null}
                <FormattedMessage
                    id="special-public-avalanche-warning"
                    defaultMessage="Special Public Avalanche Warning"
                />
            </h2>
        </Shim>
    )
}

const ImageBanner = ({ caption, credit }) => {
    return (
        <div style={stylesObj.imageBanner}>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <div dangerouslySetInnerHTML={{ __html: caption }}></div>
                </Grid>
                <Grid item xs={3}>
                    {credit && (
                        <>
                            <BsFillCameraFill style={stylesObj.creditIcon} />
                            <span style={stylesObj.credit}>{credit}</span>
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

// Utils
const stylesObj = {
    buttonLink: {
        padding: 0,
        textTransform: 'none',
        color: 'inherit',
    },
    imageBanner: {
        display: 'block',
        width: '100%',
        position: 'absolute',
        bottom: '23px',
        left: 0,
        padding: ' 0 1rem',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
    },
    imageList: {
        position: 'relative',
    },
    credit: {
        fontSize: '12px',
        marginTop: '17px',
        display: 'block',
    },
    creditIcon: {
        float: 'left',
        marginTop: '17px',
        marginRight: '5px',
    },
}
