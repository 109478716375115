import Shim from 'components/Shim/Shim'

export const Header = ({ text }) => {
    return (
        <Shim horizontal>
            <h2 style={style.name}>{text}</h2>
        </Shim>
    )
}

const style = {
    name: {
        margin: 0,
        lineHeight: 1.2,
    },
}
