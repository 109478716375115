// Via: https://web.dev/customize-install/#detect-launch-type
function getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches

    if (document.referrer.startsWith('android-app://')) {
        return 'twa'
    } else if (navigator.standalone || isStandalone) {
        return 'standalone'
    } else if (navigator.userAgent.includes('SamsungBrowser')) {
        // If user is using Samsung browser, we can't block the splash screen because none of the above checks work.
        // So just bypass the splash screen.
        return 'samsungBrowser'
    }

    return 'browser'
}

export function isInstalled() {
    return getPWADisplayMode() !== 'browser'
}
