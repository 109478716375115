import create from 'zustand'

export const DEFAULT_DRAWER_HEIGHT = 0.5
export const DRAWER_FOOTER_OFFSET = 20

const BottomSheetStore = create(set => {
    return {
        ref: null,
        setRef: ref => {
            set({ ref })
        },
    }
})

export const useSetBottomSheetRef = () => BottomSheetStore(state => state.setRef)

export const toggleBottomSheetHalfHeight = () => {
    const { ref } = BottomSheetStore.getState()
    ref.current.snapTo(({ height, snapPoints, maxHeight }) => {
        if (height === Math.min(...snapPoints)) {
            return maxHeight * 0.5
        } else if (height === maxHeight * 0.5) {
            return Math.min(...snapPoints)
        }
    })
}

export const toggleBottomSheetFullHeight = () => {
    const { ref } = BottomSheetStore.getState()
    ref.current.snapTo(({ height, snapPoints, maxHeight }) => {
        if (height === Math.min(...snapPoints)) {
            return maxHeight
        } else if (height === maxHeight * 0.5) {
            return Math.min(...snapPoints)
        }
    })
}

export const setBottomSheetToHalfHeight = () => {
    const { ref } = BottomSheetStore.getState()
    ref.current.snapTo(({ maxHeight }) => maxHeight * 0.5)
}

export const setBottomSheetToMinHeight = () => {
    const { ref } = BottomSheetStore.getState()
    ref.current.snapTo(({ snapPoints }) => Math.min(...snapPoints))
}

export const setBottomSheetToFullHeight = () => {
    const { ref } = BottomSheetStore.getState()
    ref.current.snapTo(({ snapPoints }) => Math.max(...snapPoints))
}

export const setBottomSheetToHalfHeightIfMaxHeight = () => {
    const { ref } = BottomSheetStore.getState()
    ref.current.snapTo(({ height, maxHeight }) => {
        if (height === maxHeight) {
            return maxHeight * 0.5
        }

        return height
    })
}

export const getMapOffset = () => {
    const { ref } = BottomSheetStore.getState()
    const { height } = ref.current
    const relativeHeight = height / window.innerHeight
    // The vertical offset is size of the window minus half the height of the drawer.
    // This should offset it to the middle of the viewable map area.
    // When the sheet is full (ie sheetHeight === 1), we are setting the drawer to 0.5 (default).
    // So then we use the calculation for 0.5.

    return relativeHeight === 1
        ? -window.innerHeight * (DEFAULT_DRAWER_HEIGHT / 2)
        : -window.innerHeight * (relativeHeight / 2)
}

export const isBottomSheetMinimized = () => {
    const { ref } = BottomSheetStore.getState()
    const height = ref.current?.height

    // If height doesn't exist, we assume it's minimized.
    if (!height) return true

    return height < window.innerHeight * 0.24
}
