import { useMemo } from 'react'

import useSWR from 'swr'
import { getAll } from '@avalanche-canada/prismic/clients/sponsors'

import { LOCALES } from '@avalanche-canada/constants/locales'
import {
    AVALANCHE_QUEBEC,
    KANANASKIS,
    PARKS_CANADA,
    PARKS_CANADA_BYK,
    PARKS_CANADA_GLACIER,
    PARKS_CANADA_JASPER,
    PARKS_CANADA_WATERTON,
} from '@avalanche-canada/constants/products/owners'

import Static from 'clients/static'
import { useLocale } from 'stores/SettingsStore'

export function useSponsors() {
    const sponsors = {}
    const locale = useLocale()

    const metadataKey = ['metadata']
    const { data: metadata } = useSWR(metadataKey, () => Static.getSponsors())

    for (const locale of LOCALES) {
        const documentsKey = ['documents', locale]
        const { data: documents } = useSWR(documentsKey, () => getAll(locale))

        Object.assign(sponsors, {
            [locale]:
                documents &&
                documents.map(({ uid, data }) => ({
                    uid,
                    name: data.name,
                    url: data.url,
                    logo: data['image-229'],
                })),
        })
    }

    const value = useMemo(
        () => ({
            sponsors: sponsors[locale],
            metadata: metadata,
        }),
        [locale, sponsors, metadata]
    )

    return value
}

export function useForecastSponsor(forecast) {
    const { sponsors, metadata } = useSponsors()

    return useMemo(() => {
        let uid
        const owner = forecast.owner?.value
        switch (owner) {
            case AVALANCHE_QUEBEC:
                uid = metadata?.AvQForecast || 'avalanche-quebec'
                break
            case KANANASKIS:
                uid = 'kananaskis'
                break
            case PARKS_CANADA:
            case PARKS_CANADA_BYK:
            case PARKS_CANADA_GLACIER:
            case PARKS_CANADA_JASPER:
            case PARKS_CANADA_WATERTON:
                uid = 'parks-canada'
                break
            default:
                uid = metadata.Forecast || 'acf'
        }

        return sponsors.find(sponsor => sponsor.uid === uid) || null
    }, [sponsors, forecast])
}

export function useSponsor(type) {
    const { sponsors, metadata } = useSponsors()

    return useMemo(() => {
        const uid = metadata[type]

        return sponsors.find(sponsor => sponsor.uid === uid) || null
    }, [metadata, sponsors, type])
}
