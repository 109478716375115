import { useState } from 'react'

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Button, Snackbar, Alert, AlertTitle } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { MOUNTAIN_INFORMATION_NETWORK } from '@avalanche-canada/constants/products'

import { REPORT_STEPS } from 'components/MINReporting/constants/general'
import { AUTO_HIDE_DURATION, TOP_CENTER } from 'constants/snackbar'
import { EXPLORER_LAYOUT } from 'constants/layout'
import { DATA_STORAGE_KEY } from 'layouts/MINDrawerLayout'

export const Submit = ({ reportData, setActiveStep, setLayoutWithProduct }) => {
    const [showSnackbar, setShowSnackbar] = useState(false)
    const handleViewReportButton = () => {
        if (!reportData.submissionID) {
            showSnackbar(true)
            return
        }

        setLayoutWithProduct(EXPLORER_LAYOUT, MOUNTAIN_INFORMATION_NETWORK, reportData.submissionID)
        localStorage.removeItem(DATA_STORAGE_KEY)
    }

    return (
        <>
            <Alert
                severity={'success'}
                variant="filled"
                sx={{ width: '80%s' }}
                style={{ width: '80%', margin: 'auto' }}
            >
                <AlertTitle>
                    <FormattedMessage
                        description="MIN Report success message"
                        defaultMessage="Thank you for using the Mountain Information Network!"
                    />
                </AlertTitle>
                <p>
                    <FormattedMessage
                        description="MIN Report success message"
                        defaultMessage="Your observations have been submitted."
                    />
                </p>
            </Alert>
            <div style={styles.bottomButtons}>
                <Button
                    variant="contained"
                    style={styles.backForwardButton}
                    startIcon={<ArrowBackIos />}
                    onClick={() => setActiveStep(REPORT_STEPS.REVIEW.value)}
                >
                    <FormattedMessage description="MIN Report button" defaultMessage="Edit report" />
                </Button>
                <Button
                    variant="contained"
                    style={styles.backForwardButton}
                    endIcon={<ArrowForwardIos />}
                    onClick={() => handleViewReportButton()}
                >
                    <FormattedMessage description="MIN Report button" defaultMessage="View report" />
                </Button>
            </div>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={AUTO_HIDE_DURATION}
                anchorOrigin={TOP_CENTER}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert severity={'error'} variant="filled">
                    <FormattedMessage
                        description="MIN Report snackbar"
                        defaultMessage="Sorry, we were not able to load your report. Please try again later."
                    />
                </Alert>
            </Snackbar>
        </>
    )
}

const styles = {
    flex: {
        display: 'flex',
        margin: '0.75rem 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.5rem',
    },
    bottomButtons: {
        position: 'fixed',
        bottom: 'calc(56px + env(safe-area-inset-bottom))',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 1rem',
        marginBottom: '1rem',
    },
}
