import React, { useState } from 'react'

import { get } from 'lodash'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { BsInfoCircle } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'

import { getError, outputPotentiallyZero, HelperText } from './helpers'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Spacer } from 'components/Spacer/Spacer'
import { CheckboxGroup } from './CheckboxGroup'
import { InfoLabel } from './InfoLabel'
import { InfoLabelIncidentDescription } from './InfoLabelIncidentDescription'
import { MINCommentInput } from './MINCommentInput'
import {
    ACTIVITY,
    ACTIVITY_OTHER,
    GROUP_SIZE,
    PEOPLE_BURIED,
    PEOPLE_BURIED_IMPAIRED_BREATHING,
    PEOPLE_BURIED_NORMAL_BREATHING,
    PEOPLE_INJURED,
    PEOPLE_NOT_INJURED,
    SNOW_DEPTH_AT_TRIGGER_POINT,
    TERRAIN_SHAPE,
    TERRAIN_TRAPS,
    COMMENT,
} from './constants/incidentReport'

const basePath = 'observation.incident'

export const IncidentReport = ({ report, updateReport }) => {
    const [openSizeModal, setOpenSizeModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        title: null,
        info: null,
        type: null,
    })

    const showInfoModal = (title, info, type = null) => {
        setModalContent({ title, info, type })
        setOpenSizeModal(true)
    }

    return (
        <DetailsWrapper>
            <CheckboxGroup
                options={ACTIVITY.options}
                label={ACTIVITY.label}
                selected={get(report, ACTIVITY.path) || []}
                onChange={value => updateReport(value, ACTIVITY.path)}
                singleValue
            />
            <InfoLabel info={ACTIVITY.description.info} />
            {get(report, ACTIVITY.path) === 'other' && (
                <>
                    <Spacer />
                    <TextField
                        onChange={event => updateReport(event.target.value, ACTIVITY_OTHER.path)}
                        value={get(report, ACTIVITY_OTHER.path)}
                        fullWidth
                        label={ACTIVITY_OTHER.label}
                        variant="outlined"
                    />
                    <Spacer />
                </>
            )}
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, GROUP_SIZE.path, updateReport)}
                value={outputPotentiallyZero(report, GROUP_SIZE.path)}
                error={getError(report, GROUP_SIZE)}
                helperText={<HelperText field={GROUP_SIZE} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={GROUP_SIZE.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, PEOPLE_BURIED.path, updateReport)}
                value={outputPotentiallyZero(report, PEOPLE_BURIED.path)}
                error={getError(report, PEOPLE_BURIED)}
                helperText={<HelperText field={PEOPLE_BURIED} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={PEOPLE_BURIED.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, PEOPLE_BURIED_IMPAIRED_BREATHING.path, updateReport)
                }
                value={outputPotentiallyZero(report, PEOPLE_BURIED_IMPAIRED_BREATHING.path)}
                error={getError(report, PEOPLE_BURIED_IMPAIRED_BREATHING)}
                helperText={<HelperText field={PEOPLE_BURIED_IMPAIRED_BREATHING} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={PEOPLE_BURIED_IMPAIRED_BREATHING.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, PEOPLE_BURIED_NORMAL_BREATHING.path, updateReport)}
                value={outputPotentiallyZero(report, PEOPLE_BURIED_NORMAL_BREATHING.path)}
                error={getError(report, PEOPLE_BURIED_NORMAL_BREATHING)}
                helperText={<HelperText field={PEOPLE_BURIED_NORMAL_BREATHING} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={PEOPLE_BURIED_NORMAL_BREATHING.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, PEOPLE_NOT_INJURED.path, updateReport)}
                value={outputPotentiallyZero(report, PEOPLE_NOT_INJURED.path)}
                error={getError(report, PEOPLE_NOT_INJURED)}
                helperText={<HelperText field={PEOPLE_NOT_INJURED} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={PEOPLE_NOT_INJURED.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, PEOPLE_INJURED.path, updateReport)}
                value={outputPotentiallyZero(report, PEOPLE_INJURED.path)}
                error={getError(report, PEOPLE_INJURED)}
                helperText={<HelperText field={PEOPLE_INJURED} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={PEOPLE_INJURED.label}
                variant="outlined"
            />
            <CheckboxGroup
                options={TERRAIN_SHAPE.options}
                label={TERRAIN_SHAPE.label}
                selected={get(report, TERRAIN_SHAPE.path) || []}
                onChange={value => updateReport(value, TERRAIN_SHAPE.path)}
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(
                                TERRAIN_SHAPE.description.title,
                                TERRAIN_SHAPE.description.info,
                                TERRAIN_SHAPE.description.type
                            )
                        }
                    />
                }
            />
            <CheckboxGroup
                options={SNOW_DEPTH_AT_TRIGGER_POINT.options}
                label={SNOW_DEPTH_AT_TRIGGER_POINT.label}
                selected={get(report, SNOW_DEPTH_AT_TRIGGER_POINT.path) || []}
                onChange={value => updateReport(value, SNOW_DEPTH_AT_TRIGGER_POINT.path)}
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(
                                SNOW_DEPTH_AT_TRIGGER_POINT.description.title,
                                SNOW_DEPTH_AT_TRIGGER_POINT.description.info,
                                SNOW_DEPTH_AT_TRIGGER_POINT.description.type
                            )
                        }
                    />
                }
            />
            <CheckboxGroup
                options={TERRAIN_TRAPS.options}
                label={TERRAIN_TRAPS.label}
                selected={get(report, TERRAIN_TRAPS.path) || []}
                onChange={value => updateReport(value, TERRAIN_TRAPS.path)}
            />
            <InfoLabel info={TERRAIN_TRAPS.description.info} />
            <Spacer />
            <MINCommentInput onChange={value => updateReport(value, COMMENT.path)} value={get(report, COMMENT.path)} />
            <InfoLabelIncidentDescription />
            <Modal
                open={openSizeModal}
                onClose={() => setOpenSizeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.modal}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modalContent.title}
                    </Typography>
                    {modalFormatter(modalContent)}
                    <Button style={styles.closeBtn} onClick={() => setOpenSizeModal(false)}>
                        <FormattedMessage description="MIN Form modal close button" defaultMessage="Close" />
                    </Button>
                </Box>
            </Modal>
        </DetailsWrapper>
    )
}

// utils and helpers
const modalFormatter = modalContent => {
    if (modalContent.type === 'bullets') {
        return (
            <ul>
                {modalContent.info.map(info => (
                    <li>{info}</li>
                ))}
            </ul>
        )
    }

    return Array.isArray(modalContent.info) ? modalContent.info.map(info => <p>{info}</p>) : <p>{modalContent.info}</p>
}

const styles = {
    closeBtn: {
        float: 'right',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    previewButton: {
        display: 'block',
        margin: '0px auto 20px',
    },
}
