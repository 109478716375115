import { defineMessages } from 'react-intl'

import * as Products from '@avalanche-canada/constants/products'

export const TitleMessages = defineMessages({
    [Products.FORECAST]: {
        id: 'avalanche-forecasts',
        defaultMessage: 'Avalanche Forecasts',
        description: 'Layer menu title',
    },
    [Products.MOUNTAIN_INFORMATION_NETWORK]: {
        id: 'mountain-information-network',
        defaultMessage: 'Mountain Information Network',
        description: 'Layer menu title',
    },
    [Products.MOUNTAIN_CONDITIONS_REPORT]: {
        id: 'mountain-conditions-reports',
        defaultMessage: 'Mountain Conditions Reports',
        description: 'Layer menu title',
    },
    [Products.WEATHER_STATION]: {
        id: 'weather-stations',
        defaultMessage: 'Weather stations',
        description: 'Layer menu title',
    },
    [Products.ACCIDENT]: {
        id: 'fatal-recreational-accidents',
        defaultMessage: 'Fatal Recreational Accidents',
        description: 'Layer menu title',
    },
    [Products.CLOSURE_ZONE]: {
        id: 'closure-zones',
        defaultMessage: 'Closure Zones',
        description: 'Layer menu title',
    },
    [Products.ICE_CLIMB]: {
        id: 'ice-climb',
        defaultMessage: 'Ice Climbing Atlas',
    },
})
