import * as React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { DateTime } from './'

DateRange.propTypes = {
    from: PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.instanceOf(Date).isRequired,
}

// TODO Should remove and use <FormattedDateTimeRange> instead!

export default function DateRange({ from, to }) {
    return (
        <React.Fragment>
            <FormattedMessage description="Component time/DateRange" defaultMessage="From" /> <DateTime value={from} />{' '}
            <FormattedMessage description="Component time/DateRange" defaultMessage="to" /> <DateTime value={to} />
        </React.Fragment>
    )
}
