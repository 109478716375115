import React from 'react'

import TextareaAutosize from '@mui/material/TextareaAutosize'
import { FormattedMessage } from 'react-intl'

export const MINCommentInput = ({ onChange, value }) => {
    return (
        <div style={styles.container}>
            <p style={styles.label}>
                <FormattedMessage description="MIN Form Field" defaultMessage="Comments" />
            </p>
            <TextareaAutosize
                minRows={7}
                style={styles.comment}
                onChange={e => onChange(e.target.value)}
                value={value}
            />
        </div>
    )
}

const styles = {
    comment: {
        width: '100%',
        background: 'var(--background-color)',
        padding: '15px',
        color: 'var(--text-color)',
        borderRadius: '5px',
        fontSize: '16px',
        overflow: 'auto',
    },
    label: {
        background: 'var(--background-color)',
        color: 'var(--gray)',
        zIndex: 1000,
        position: 'absolute',
        paddingRight: '8px',
        paddingLeft: '8px',
        top: '-22px',
        left: '10px',
        fontSize: '13px',
    },
    container: {
        position: 'relative',
        margin: '5px 0px',
    },
}
