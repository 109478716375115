import { useIntl } from 'react-intl'
import closureZones from './ClosureZone.svg'

export default function ClosureZone({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        id: 'icons.closure-zone',
        defaultMessage: 'Closure Zone icon',
        description: 'Icon for Closure Zone',
    })

    return <img width={width} height={height} alt={alt} {...props} src={closureZones.src} />
}
