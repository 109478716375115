import React from 'react'

import { useForecastSponsor, useSponsor } from 'hooks/data/sponsors'
import { forceHttps } from 'utils/url'
import { handleOutboundSponsorClick } from 'services/analytics'

import styles from './Sponsor.module.css'

export const Sponsor = ({ type, payload = null }) => {
    let sponsor
    if (type === 'Forecast') {
        sponsor = useForecastSponsor(payload?.product)
    } else if (type === 'MIN' || type === 'Weather') {
        sponsor = useSponsor(type)
    }

    if (!sponsor) {
        return null
    }

    const { name, logo, url } = sponsor

    return (
        <div className={styles.Wrapper}>
            <a href={url} target={name} title={name} onClick={handleOutboundSponsorClick}>
                {logo ? <img src={forceHttps(logo)} alt={name} title={name} className={styles.Logo} /> : name}
            </a>
        </div>
    )
}
