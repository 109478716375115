import React from 'react'

import { AppLayout } from 'layouts/AppLayout'
import { useDataLoading } from 'hooks/data/useDataLoading'
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'
import { InstallationInstructions } from 'components/misc/InstallationInstructions'
import { isInstalled } from 'utils/pwa'

export default function Index() {
    const { isLoading } = useDataLoading()

    if (!isInstalled() && process.env.NODE_ENV !== 'development') {
        return <InstallationInstructions />
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    return <AppLayout />
}
