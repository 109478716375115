import React from 'react'

import { useSpaws } from 'hooks/data/SPAW'
import { Page, SpawNotFound } from 'layouts/products/spaw'
import { CloseButton } from 'components/BottomSheet/CloseButton/CloseButton'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { useGoToHome } from 'stores/ViewStore'

export const SPAW = () => {
    const { data } = useSpaws()
    const goToHome = useGoToHome()

    return (
        <DetailsWrapper>
            <CloseButton callback={goToHome} />
            {data ? <Page spaw={data} /> : <SpawNotFound />}
        </DetailsWrapper>
    )
}
