import React from 'react'
import { useIntl } from 'react-intl'
import weatherStation from './weather-station.svg'

export default function WeatherStation({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        id: 'icons.weather-station',
        defaultMessage: 'Weather station icon',
        description: 'Icon for weather station',
    })

    return <img width={width} height={height} alt={alt} {...props} src={weatherStation.src} />
}
