import { useCallback } from 'react'

import * as Context from 'contexts/map'
import { getMapOffset, setBottomSheetToHalfHeightIfMaxHeight } from 'stores/BottomSheetStore'

export function useFlyTo() {
    const map = Context.useMap()

    return useCallback(
        (center, zoom = 13) => {
            // Only set the drawer height if the drawer is open to the top.
            setBottomSheetToHalfHeightIfMaxHeight()
            map?.flyTo({
                center,
                zoom,
                offset: [0, getMapOffset()],
            })
        },
        [map]
    )
}
