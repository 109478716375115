import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './Close.module.css'

Close.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
}

export default function Close({ children = '×', ...props }) {
    return (
        <button {...props} className={clsx(styles.Close, props.className)}>
            {children}
        </button>
    )
}
