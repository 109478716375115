import { FormattedMessage } from 'react-intl'

// Incident Report
export const ACTIVITY = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Activity" />,
    path: 'observations.incident.groupActivity',
    options: [
        {
            key: 'snowmobiling',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowmobiling" />,
        },
        {
            key: 'skiing',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Skiing" />,
        },
        {
            key: 'hikingScrambling',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Hiking/Scrambling" />,
        },
        {
            key: 'climbingMountainering',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Climbing/Mountainering" />,
        },
        {
            key: 'snowshoeing',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowshoeing" />,
        },
        {
            key: 'tobogganing',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Tobogganing" />,
        },
        {
            key: 'other',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Other" />,
        },
    ],
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Choose an activity while the incident happened."
            />
        ),
    },
}

export const ACTIVITY_OTHER = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Other activity" />,
    path: 'observations.incident.otherActivityDescription',
}

export const GROUP_SIZE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Total in the group?" />,
    path: 'observations.incident.groupSize',
    min: 1,
    max: 100,
}

export const PEOPLE_BURIED = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="People fully buried?" />,
    path: 'observations.incident.numberFullyBuried',
    min: 0,
    max: 100,
}

export const PEOPLE_BURIED_IMPAIRED_BREATHING = {
    label: (
        <FormattedMessage description="MIN Form Field" defaultMessage="People partly buried with impaired breathing?" />
    ),
    path: 'observations.incident.numberPartlyBuriedImpairedBreathing',
    min: 0,
    max: 100,
}

export const PEOPLE_BURIED_NORMAL_BREATHING = {
    label: (
        <FormattedMessage description="MIN Form Field" defaultMessage="People partly buried with normal breathing?" />
    ),
    path: 'observations.incident.numberPartlyBuriedAbleBreathing',
    min: 0,
    max: 100,
}

export const PEOPLE_NOT_INJURED = {
    label: (
        <FormattedMessage description="MIN Form Field" defaultMessage="People not injured (caught but not buried)?" />
    ),
    path: 'observations.incident.numberCaughtOnly',
    min: 0,
    max: 100,
}

export const PEOPLE_INJURED = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="People injured (caught but not buried)?" />,
    path: 'observations.incident.numberInjured',
    min: 0,
    max: 100,
}

export const TERRAIN_SHAPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Terrain shape at trigger point" />,
    path: 'observations.incident.terrainShapeTriggerPoint',
    options: [
        {
            key: 'convex',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="convex" />,
        },
        {
            key: 'concave',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="concave" />,
        },
        {
            key: 'planar',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="planar" />,
        },
        {
            key: 'unsupported',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="unsupported" />,
        },
    ],
    description: {
        type: 'bullets',
        info: [
            <FormattedMessage description="MIN Form Field" defaultMessage="Convex: a roll" />,
            <FormattedMessage description="MIN Form Field" defaultMessage="Concave: bowl-shaped" />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Planar: smooth with no significant convexities or concavities"
            />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Unsupported: a slope that drops off abruptly at the bottom"
            />,
        ],
    },
}

export const SNOW_DEPTH_AT_TRIGGER_POINT = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snow depth at trigger point" />,
    path: 'observations.incident.snowDepthTriggerPoint',
    options: [
        {
            key: 'shallow',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Shallow" />,
        },
        {
            key: 'deep',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Deep" />,
        },
        {
            key: 'average',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Average" />,
        },
        {
            key: 'variable',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Variable" />,
        },
    ],
    description: {
        type: 'bullets',
        title: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="The depth of the snowpack compared to the average conditions in the area:"
            />
        ),
        info: [
            <FormattedMessage description="MIN Form Field" defaultMessage="Shallow: shallower than average" />,
            <FormattedMessage description="MIN Form Field" defaultMessage="Deep: deeper than average" />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Average: about the same as everywhere else"
            />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Variable: depth varies significantly in the place where the avalanche started"
            />,
        ],
    },
}

export const TERRAIN_TRAPS = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Terrain traps" />,
    path: 'observations.incident.terrainTrap',
    options: [
        {
            key: 'noTrap',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="No obvious terrain trap" />,
        },
        {
            key: 'gullyDepression',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Gully or depression" />,
        },
        {
            key: 'slopeTransitionOrBench',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Slope transition or bench" />,
        },
        {
            key: 'trees',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Trees" />,
        },
        {
            key: 'cliff',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cliff" />,
        },
    ],
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Terrain traps are features that increase the consequences of an avalanche."
            />
        ),
    },
}

export const COMMENT = {
    path: 'observations.incident.description',
}

export const INCIDENT_FIELDS = [
    ACTIVITY,
    GROUP_SIZE,
    PEOPLE_BURIED,
    PEOPLE_BURIED_IMPAIRED_BREATHING,
    PEOPLE_BURIED_NORMAL_BREATHING,
    PEOPLE_NOT_INJURED,
    PEOPLE_INJURED,
    TERRAIN_SHAPE,
    SNOW_DEPTH_AT_TRIGGER_POINT,
    TERRAIN_TRAPS,
    COMMENT,
]
