import React from 'react'

import { get } from 'lodash'

import { CheckboxGroup } from 'components/MINReporting/CheckboxGroup'
import { MINSelect } from 'components/MINReporting/MINSelect'
import { Spacer } from 'components/Spacer/Spacer'
import { SNOW_CONDITIONS, RIDING_QUALITY, RIDE_TYPE, STAYED_AWAY } from './constants/quickReport'

export const SnowConditionsForm = ({ report, updateReport }) => {
    return (
        <>
            <CheckboxGroup
                options={RIDING_QUALITY.options}
                label={RIDING_QUALITY.label}
                selected={get(report, RIDING_QUALITY.path) || ''}
                onChange={value => updateReport(value, RIDING_QUALITY.path)}
                singleValue
            />
            <Spacer />
            <CheckboxGroup
                options={SNOW_CONDITIONS.options}
                label={SNOW_CONDITIONS.label}
                selected={get(report, SNOW_CONDITIONS.path) || []}
                onChange={value => updateReport(value, SNOW_CONDITIONS.path)}
            />
            <Spacer />
            <CheckboxGroup
                options={RIDE_TYPE.options}
                label={RIDE_TYPE.label}
                selected={get(report, RIDE_TYPE.path) || []}
                onChange={value => updateReport(value, RIDE_TYPE.path)}
            />
            <Spacer />
            <CheckboxGroup
                options={STAYED_AWAY.options}
                label={STAYED_AWAY.label}
                selected={get(report, STAYED_AWAY.path) || []}
                onChange={value => updateReport(value, STAYED_AWAY.path)}
            />
        </>
    )
}

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}
