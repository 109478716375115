import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Button } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'

export const Share = ({ handleShareClick }) => {
    if (navigator.share === undefined) {
        return null
    }

    return (
        <Button variant="outlined" endIcon={<ShareIcon />} onClick={handleShareClick}>
            <FormattedMessage defaultMessage="Share" description="Social share button" />
        </Button>
    )
}
