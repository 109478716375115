import useSWRImmutable from 'swr'

import { getEvery } from '@avalanche-canada/prismic/clients/generic'
import { useLocale } from 'stores/SettingsStore'

export function useDisclaimers() {
    const locale = useLocale()
    const key = 'disclaimers'

    const { data: disclaimers } = useSWRImmutable(key, () => getEvery(uids, locale))

    return {
        disclaimers,
    }
}

const uids = [
    'forecast-disclaimer',
    'areas-covered-bulletin',
    'forecast-disclaimer-avalanche-quebec',
    'forecast-disclaimer-parks-canada',
    'avertissement-previsions-pas-completement-traduites',
    'forecast-info-avalanche-quebec',
]
