import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'

import { useLocalStorage } from 'hooks'
import { useUsername } from 'contexts/authentication'
import { LayerMenu } from 'components/LayerMenu/LayerMenu'
import MapLayout from 'components/map/MapLayout'
import { BottomSheet } from 'components/BottomSheet/BottomSheet'
import { handleMapClick } from 'components/MINReporting/helpers'
import { NewMINReport } from 'components/map/Drawer/NewMINReport'
import { setBottomSheetToHalfHeight, DEFAULT_DRAWER_HEIGHT } from 'stores/BottomSheetStore'
import { TAGS, SCHEMA_VERSIONS } from 'components/MINReporting/constants/general'
import { Marker } from 'contexts/map'
import { MountainInformationNetwork } from 'components/icons'
import { useEditingMIN } from 'stores/ViewStore'

export const DATA_STORAGE_KEY = 'mountain-information-network-form-data'

export const MINDrawerLayout = ({ setLayoutWithProduct, layout, setLayout }) => {
    const username = useUsername()
    const editingMIN = useEditingMIN()
    // Two scenarios:
    // 1. Editing an existing MIN report. In this case, we need to load the existing data from the API, and clear out any draft data in local storage.
    // 2. Creating a new MIN report. In this case, we need to check if there is any draft data in local storage.
    const initialReportValue = editingMIN
        ? editingMIN
        : {
              ...editingMIN,
              schemaVersions: SCHEMA_VERSIONS,
              tags: [TAGS.SNOW_CONDITIONS],
              username,
              draft: true,
          }
    if (editingMIN) {
        localStorage.removeItem(DATA_STORAGE_KEY) // Clear out any draft data, per scenario 1.
    }
    const [reportData, setReportData] = useLocalStorage(DATA_STORAGE_KEY, initialReportValue)
    const [showMask, setShowMask] = useState(shouldShowMask(editingMIN, reportData))
    const [locationUpdate, setLocationUpdate] = useState(null)

    const runClearValuesSideEffects = () => {
        setShowMask(true)
        setBottomSheetToHalfHeight()
    }

    const defaultSnap = ({ maxHeight }) => (showMask ? maxHeight * DEFAULT_DRAWER_HEIGHT : maxHeight)

    return (
        <>
            {showMask && (
                <div onClick={() => setShowMask(false)} style={style.mask}>
                    <h2 style={style.locationGuidance}>
                        <FormattedMessage
                            description="MIN report mask"
                            defaultMessage="Click a point on the map to select a location for this MIN report"
                        />
                    </h2>
                    <Button variant="contained" onClick={() => setShowMask(false)}>
                        <FormattedMessage description="MIN report mask" defaultMessage="Got it!" />
                    </Button>
                </div>
            )}
            <MapLayout
                mapClickHandler={event => handleMapClick(event, setLocationUpdate)}
                showLayers={false}
                showMask={showMask}
            >
                <LayerMenu />
                <BottomSheet defaultSnap={defaultSnap} layout={layout} setLayout={setLayout}>
                    <NewMINReport
                        locationUpdate={locationUpdate}
                        setLayoutWithProduct={setLayoutWithProduct}
                        setLocationUpdate={setLocationUpdate}
                        runClearValuesSideEffects={runClearValuesSideEffects}
                        reportData={reportData}
                        setReportData={setReportData}
                        initialReportValue={initialReportValue}
                    />
                </BottomSheet>
                {reportData?.location?.longitude && reportData?.location?.latitude && (
                    <Marker
                        lnglat={{
                            lng: reportData.location.longitude,
                            lat: reportData.location.latitude,
                        }}
                    >
                        <MountainInformationNetwork width={30} height={30} />
                    </Marker>
                )}
            </MapLayout>
        </>
    )
}

const shouldShowMask = (editingMIN, reportData) => {
    if (editingMIN) {
        return false
    }
    if (reportData?.location?.longitude && reportData?.location?.latitude) {
        return false
    }

    return true
}

const style = {
    mask: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#6b6b6ba6',
        padding: '20px 60px 20px 20px',
        zIndex: 2,
    },
    locationGuidance: {
        fontWeight: '300',
        color: '#fff',
    },
}
