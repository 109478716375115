import React from 'react'
import { FormattedMessage } from 'react-intl'

export const UpdateOverlay = () => {
    return (
        <div style={styles.mask}>
            <div>
                <FormattedMessage
                    id="updateOverlay.message"
                    defaultMessage="Updating…"
                    description="Update overlay message"
                />
            </div>
        </div>
    )
}

const styles = {
    mask: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1em',
    },
}
