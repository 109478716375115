import { useIntl } from 'react-intl'

import iceClimbingAtlas from './ice-climbing-atlas.svg'

export default function IceClimbingAtlas({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        defaultMessage: 'Ice Climbing Atlas',
    })

    return <img width={width} height={height} alt={alt} {...props} src={iceClimbingAtlas.src} />
}
