import PropTypes from 'prop-types'
import { has } from 'lodash'
import Section from './Section'
import * as Description from 'components/description'
import * as Context from '../Context'
import Value from './Value'

DescriptionList.propTypes = {
    header: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default function DescriptionList({ header, content }) {
    const report = Context.useReport()

    if (content.some(item => has(report, item.path))) {
        return (
            <Section header={header}>
                <Description.List bordered>
                    {content.map(item => (
                        <Item key={item.path} {...item} />
                    ))}
                </Description.List>
            </Section>
        )
    }

    return null
}

// Utils
function Item({ path, term, description }) {
    const value = Context.useReportValue(path)

    if (value === undefined) {
        return null
    }

    return (
        <Description.Entry term={term}>
            <Value value={value} description={description} />
        </Description.Entry>
    )
}
