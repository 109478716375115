import React from 'react'

import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useRouter } from 'next/router'

export const Back = () => {
    const router = useRouter()

    return (
        <Button onClick={() => router.back()} variant="outlined">
            <FormattedMessage defaultMessage="Back" description="Back button" />
        </Button>
    )
}
