import React from 'react'

import { Provider as MapStateProvider } from 'contexts/map/state'
import { Provider as LayersProvider } from 'contexts/layers'
import { supported } from 'utils/mapbox'
import Map from './Map'

export default ({ children, mapClickHandler, showLayers = true, setProduct }) => {
    if (!supported()) {
        return <p>{'Error: Mapbox is not supported!'}</p>
    }

    return (
        <LayersProvider>
            <MapStateProvider>
                <Map setProduct={setProduct} mapClickHandler={mapClickHandler} showLayers={showLayers}>
                    {children}
                </Map>
            </MapStateProvider>
        </LayersProvider>
    )
}
