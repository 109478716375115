import React from 'react'

import Shim from 'components/Shim/Shim'
import { useFlyTo } from 'hooks/useFlyTo'
import { Locate } from 'components/button/Locate'

export const HeaderWithLocate = ({ text, location }) => {
    const flyTo = useFlyTo()

    const handleLocateClick = () => {
        flyTo(location)
    }

    return (
        <Shim horizontal>
            <div style={style.flex}>
                <h2 style={style.name}>{text}</h2>
                <Locate onClick={handleLocateClick} />
            </div>
        </Shim>
    )
}

const style = {
    name: {
        margin: 0,
        lineHeight: 1.2,
    },
    flex: {
        display: 'flex',
        margin: '0.75rem 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.5rem',
    },
}
