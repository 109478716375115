import Button from '@mui/material/Button'

import { ACCIDENT, MOUNTAIN_INFORMATION_NETWORK, SPAW } from '@avalanche-canada/constants/products'

export function href(url) {
    if (isAvalancheCanada(url)) {
        return url.replace(avalancheCanadaPathRegex, '') || '/'
    }

    return url
}

export function isExternal(path) {
    if (typeof path !== 'string') {
        return false
    }

    return isExternalRegExp.test(path)
}

export function isAvalancheCanada(path) {
    if (typeof path !== 'string') {
        return false
    }

    return avalancheCanadaPathRegex.test(path)
}

const avalancheCanadaPathRegex = /^(http|https):\/\/(www.|)avalanche.ca/
const isExternalRegExp = new RegExp('^(https|http)://')

export function forceHttps(url) {
    return typeof url === 'string' ? url.replace(/^http:/, 'https:') : url
}

// A recursive function to check all the children of a node and replace the anchor tags
// with buttons that have the same text as the anchor tag to link to elements within the app.
export const replaceAnchorTagsRecursive = (element, handleOnClick) => {
    let newElement = element

    if (element.props && element.props.children && Array.isArray(element.props.children)) {
        element.props.children.forEach((child, index) => {
            let id, type
            if (isMINLink(child)) {
                type = MOUNTAIN_INFORMATION_NETWORK
                id = parseMINId(child.props.href)
            }

            if (isFatalityLink(child)) {
                type = ACCIDENT
                id = parseFatalityId(child.props.href)
            }

            if (isSPAWLink(child)) {
                type = SPAW
                id = parseSPAWId(child.props.href)
            }

            if (id && type) {
                // Replace the link with a button
                // The 'here' text is in case we don't have any text in the children, which shouldn't happen.
                // But just in case we provide some here text for the link.
                child = (
                    <Button
                        onClick={() => handleOnClick(id, type)}
                        variant="text"
                        style={{
                            textTransform: 'none',
                            textAlign: 'left',
                            textDecoration: 'underline',
                            padding: 0,
                            marginBottom: '1px',
                            minWidth: 'unset',
                            fontSize: 'inherit',
                        }}
                    >
                        {child.props.children ? (
                            child.props.children[0]
                        ) : (
                            <FormattedMessage defaultMessage="here" description="link to MIN or Fatality or SPAW" />
                        )}
                    </Button>
                )

                // Replace the child in the parent
                newElement.props.children[index] = child
            }

            if (child.props && child.props.children) {
                replaceAnchorTagsRecursive(child, handleOnClick)
            }
        })
    }

    return element
}

const isMINLink = child => {
    if (
        child.type === 'a' &&
        child.props.href &&
        child.props.href.includes('avalanche.ca') &&
        (child.props.href.includes('mountain-information-network-submissions') ||
            child.props.href.includes('mountain-information-network/submissions'))
    ) {
        return true
    }
}

const isFatalityLink = child => {
    if (
        child.type === 'a' &&
        child.props.href &&
        child.props.href.includes('avalanche.ca') &&
        child.props.href.includes('fatal-accidents')
    ) {
        return true
    }
}

const isSPAWLink = child => {
    if (
        child.type === 'a' &&
        child.props.href &&
        child.props.href.includes('avalanche.ca') &&
        child.props.href.includes('spaw/')
    ) {
        return true
    }
}

const parseMINId = href => {
    const url = new URL(href)
    const searchParams = new URLSearchParams(url.search)
    if (searchParams.has('panel')) {
        return searchParams
            .get('panel')
            .replace('mountain-information-network-submissions%2F', '')
            .replace('mountain-information-network-submissions/', '')
            .replace('mountain-information-network/submissions%2F', '')
            .replace('mountain-information-network/submissions/', '')
    } else {
        return url.pathname
            .replace('/mountain-information-network-submissions%2F', '')
            .replace('/mountain-information-network-submissions/', '')
            .replace('/mountain-information-network/submissions%2F', '')
            .replace('/mountain-information-network/submissions/', '')
    }
}

const parseFatalityId = href => {
    const url = new URL(href)
    const searchParams = new URLSearchParams(url.search)
    if (searchParams.has('panel')) {
        return searchParams.get('panel').replace('fatal-accidents%2F', '').replace('fatal-accidents/', '')
    } else {
        return url.pathname.replace('/fatal-accidents%2F', '').replace('/fatal-accidents/', '')
    }
}

const parseSPAWId = href => {
    const url = new URL(href)
    // ID is the string after 'spaw/' in the url. Return that.
    return url.pathname.replace('/spaw/', '').replace('/spaw%2F', '').replace('%2Fspaw%2F', '')
}
