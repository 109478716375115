import React, { useState } from 'react'

import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField, Box, Button, Typography, Modal, InputAdornment, IconButton } from '@mui/material'
import { BsInfoCircle } from 'react-icons/bs'
import ClearIcon from '@mui/icons-material/Clear'

import {
    AVALANCHE_DATE,
    AVALANCHE_OCCURRENCE,
    NUMBER_OF_AVALANCHES,
    AVALANCHE_SIZE,
    SLAB_THICKNESS,
    SLAB_WIDTH,
    RUN_LENGTH,
    AVALANCHE_CHARACTERISTICS,
    TRIGGER_TYPE,
    TRIGGER_SUB_TYPE,
    TRIGGER_DISTANCE,
    START_ZONE_ASPECT,
    START_ZONE_ELEVATION,
    START_ZONE_ELEVATION_BAND,
    START_ZONE_INCLINE,
    RUNOUT_ZONE_ELEVATION,
    WEAK_LAYER_BURIAL_DATE,
    WEAK_LAYER_CRYSTAL_TYPE,
    CRUST_NEAR_WEAK_LAYER,
    WIND_EXPOSURE,
    VEGETATION_COVER,
    COMMENT,
} from './constants/avalancheReport'
import { getError, HelperText, outputPotentiallyZero, numberHandler } from './helpers'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Spacer } from 'components/Spacer/Spacer'
import { CheckboxGroup } from './CheckboxGroup'
import { InfoLabel } from './InfoLabel'
import { MINCommentInput } from './MINCommentInput'
import { CheckboxYesNo } from './CheckboxYesNo'

const basePath = 'observations.avalanche'

export const AvalancheReport = ({ report, updateReport }) => {
    const [openSizeModal, setOpenSizeModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        title: null,
        info: null,
    })

    const showInfoModal = (title, info) => {
        setModalContent({ title, info })
        setOpenSizeModal(true)
    }

    const clearDate = (path, event) => {
        event.stopPropagation()
        updateReport(null, path)
    }

    const Adornment = ({ path }) => {
        return (
            get(report, path) && (
                <InputAdornment onClick={event => clearDate(path, event)} position="end">
                    <IconButton>
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            )
        )
    }

    return (
        <DetailsWrapper>
            <Spacer />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label={AVALANCHE_DATE.label}
                    value={get(report, AVALANCHE_DATE.path) || null}
                    onChange={value => updateReport(value.toISOString(), AVALANCHE_DATE.path)}
                    renderInput={params => (
                        <TextField
                            required={AVALANCHE_DATE.required}
                            fullWidth
                            helperText={<HelperText report={report} field={AVALANCHE_DATE} basePath={basePath} />}
                            {...params}
                            error={getError(report, AVALANCHE_DATE, basePath)}
                            InputProps={{
                                endAdornment: <Adornment path={AVALANCHE_DATE.path} />,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
            <InfoLabel info={AVALANCHE_DATE.description.info} />
            <CheckboxGroup
                options={AVALANCHE_OCCURRENCE.options}
                label={AVALANCHE_OCCURRENCE.label}
                selected={get(report, AVALANCHE_OCCURRENCE.path) || []}
                onChange={value => updateReport(value, AVALANCHE_OCCURRENCE.path)}
                singleRow
                singleValue
                icon={<BsInfoCircle onClick={() => showInfoModal(null, AVALANCHE_OCCURRENCE.description.info)} />}
            />
            <Spacer />
            <CheckboxGroup
                options={NUMBER_OF_AVALANCHES.options}
                label={NUMBER_OF_AVALANCHES.label}
                selected={get(report, NUMBER_OF_AVALANCHES.path) || []}
                onChange={value => updateReport(value, NUMBER_OF_AVALANCHES.path)}
                singleValue
            />
            <Spacer />
            <CheckboxGroup
                options={AVALANCHE_SIZE.options}
                label={AVALANCHE_SIZE.label}
                selected={get(report, AVALANCHE_SIZE.path) || []}
                onChange={value => updateReport(value, AVALANCHE_SIZE.path)}
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() => showInfoModal(AVALANCHE_SIZE.description.title, AVALANCHE_SIZE.description.info)}
                    />
                }
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, SLAB_THICKNESS.path, updateReport)}
                value={outputPotentiallyZero(report, SLAB_THICKNESS.path)}
                error={getError(report, SLAB_THICKNESS)}
                helperText={<HelperText field={SLAB_THICKNESS} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={SLAB_THICKNESS.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, SLAB_WIDTH.path, updateReport)}
                value={outputPotentiallyZero(report, SLAB_WIDTH.path)}
                error={getError(report, SLAB_WIDTH)}
                helperText={<HelperText field={SLAB_WIDTH} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={SLAB_WIDTH.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, RUN_LENGTH.path, updateReport)}
                value={outputPotentiallyZero(report, RUN_LENGTH.path)}
                error={getError(report, RUN_LENGTH)}
                helperText={<HelperText field={RUN_LENGTH} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={RUN_LENGTH.label}
                variant="outlined"
            />
            <InfoLabel info={RUN_LENGTH.description.info} />
            <CheckboxGroup
                options={AVALANCHE_CHARACTERISTICS.options}
                label={AVALANCHE_CHARACTERISTICS.label}
                selected={get(report, AVALANCHE_CHARACTERISTICS.path) || []}
                onChange={value => updateReport(value, AVALANCHE_CHARACTERISTICS.path)}
            />
            <Spacer />
            <CheckboxGroup
                options={TRIGGER_TYPE.options}
                label={TRIGGER_TYPE.label}
                selected={get(report, TRIGGER_TYPE.path) || []}
                onChange={value => updateReport(value, TRIGGER_TYPE.path)}
                singleValue
            />
            <Spacer />
            <CheckboxGroup
                options={TRIGGER_SUB_TYPE.options}
                label={TRIGGER_SUB_TYPE.label}
                selected={get(report, TRIGGER_SUB_TYPE.path) || []}
                onChange={value => updateReport(value, TRIGGER_SUB_TYPE.path)}
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(TRIGGER_SUB_TYPE.description.title, TRIGGER_SUB_TYPE.description.info)
                        }
                    />
                }
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, TRIGGER_DISTANCE.path, updateReport)}
                value={outputPotentiallyZero(report, TRIGGER_DISTANCE.path)}
                error={getError(report, TRIGGER_DISTANCE)}
                helperText={<HelperText field={TRIGGER_DISTANCE} report={report} basePath={basePath} />}
                fullWidth
                label={TRIGGER_DISTANCE.label}
                type="number"
                variant="outlined"
            />
            <InfoLabel info={TRIGGER_DISTANCE.description.info} />
            <CheckboxGroup
                options={START_ZONE_ASPECT.options}
                label={START_ZONE_ASPECT.label}
                selected={get(report, START_ZONE_ASPECT.path) || []}
                onChange={value => updateReport(value, START_ZONE_ASPECT.path)}
            />
            <Spacer />
            <CheckboxGroup
                options={START_ZONE_ELEVATION_BAND.options}
                label={START_ZONE_ELEVATION_BAND.label}
                selected={get(report, START_ZONE_ELEVATION_BAND.path) || []}
                onChange={value => updateReport(value, START_ZONE_ELEVATION_BAND.path)}
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, START_ZONE_ELEVATION.path, updateReport)}
                value={outputPotentiallyZero(report, START_ZONE_ELEVATION.path)}
                error={getError(report, START_ZONE_ELEVATION)}
                helperText={<HelperText field={START_ZONE_ELEVATION} report={report} basePath={basePath} />}
                fullWidth
                label={START_ZONE_ELEVATION.label}
                type="number"
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, START_ZONE_INCLINE.path, updateReport)}
                value={outputPotentiallyZero(report, START_ZONE_INCLINE.path)}
                error={getError(report, START_ZONE_INCLINE)}
                helperText={<HelperText field={START_ZONE_INCLINE} report={report} basePath={basePath} />}
                fullWidth
                label={START_ZONE_INCLINE.label}
                type="number"
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, RUNOUT_ZONE_ELEVATION.path, updateReport)}
                value={outputPotentiallyZero(report, RUNOUT_ZONE_ELEVATION.path)}
                error={getError(report, RUNOUT_ZONE_ELEVATION)}
                helperText={<HelperText field={RUNOUT_ZONE_ELEVATION} report={report} basePath={basePath} />}
                fullWidth
                label={RUNOUT_ZONE_ELEVATION.label}
                type="number"
                variant="outlined"
            />
            <InfoLabel info={RUNOUT_ZONE_ELEVATION.description.info} />
            <Spacer />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={WEAK_LAYER_BURIAL_DATE.label}
                    value={get(report, WEAK_LAYER_BURIAL_DATE.path) || null}
                    onChange={value =>
                        updateReport(value ? value.toISOString().split('T')[0] : null, WEAK_LAYER_BURIAL_DATE.path)
                    }
                    InputProps={{
                        endAdornment: <Adornment path={WEAK_LAYER_BURIAL_DATE.path} />,
                    }}
                    renderInput={params => (
                        <TextField required={WEAK_LAYER_BURIAL_DATE.required} fullWidth {...params} />
                    )}
                />
            </LocalizationProvider>
            <InfoLabel info={WEAK_LAYER_BURIAL_DATE.description.info} />
            <CheckboxGroup
                options={WEAK_LAYER_CRYSTAL_TYPE.options}
                label={WEAK_LAYER_CRYSTAL_TYPE.label}
                selected={get(report, WEAK_LAYER_CRYSTAL_TYPE.path) || []}
                onChange={value => updateReport(value, WEAK_LAYER_CRYSTAL_TYPE.path)}
            />
            <Spacer />
            <CheckboxYesNo
                label={CRUST_NEAR_WEAK_LAYER.label}
                onChange={value => updateReport(value, CRUST_NEAR_WEAK_LAYER.path)}
                value={get(report, CRUST_NEAR_WEAK_LAYER.path)}
            />
            <Spacer />
            <CheckboxGroup
                options={WIND_EXPOSURE.options}
                label={WIND_EXPOSURE.label}
                selected={get(report, WIND_EXPOSURE.path) || []}
                onChange={value => updateReport(value, WIND_EXPOSURE.path)}
                singleValue
            />
            <Spacer />
            <CheckboxGroup
                options={VEGETATION_COVER.options}
                label={VEGETATION_COVER.label}
                selected={get(report, VEGETATION_COVER.path) || []}
                onChange={value => updateReport(value, VEGETATION_COVER.path)}
                singleValue
            />
            <Spacer />
            <MINCommentInput onChange={value => updateReport(value, COMMENT.path)} value={get(report, COMMENT.path)} />
            <Modal
                open={openSizeModal}
                onClose={() => setOpenSizeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.modal}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modalContent.title}
                    </Typography>
                    {Array.isArray(modalContent.info) ? (
                        modalContent.info.map(info => <p>{info}</p>)
                    ) : (
                        <p>{modalContent.info}</p>
                    )}
                    <Button style={styles.closeBtn} onClick={() => setOpenSizeModal(false)}>
                        <FormattedMessage description="MIN Form modal close button" defaultMessage="Close" />
                    </Button>
                </Box>
            </Modal>
        </DetailsWrapper>
    )
}

const styles = {
    closeBtn: {
        float: 'right',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    previewButton: {
        display: 'block',
        margin: '0px auto 20px',
    },
}
