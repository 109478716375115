import PropTypes from 'prop-types'
import { DateElement, Range } from 'components/time'

DateSet.propTypes = {
    values: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
}

export default function DateSet({ values = [] }) {
    if (!Array.isArray(values) || values.length === 0) {
        return null
    }

    const [from, to] = values

    return <div style={styles.date}>{to ? <Range from={from} to={to} /> : <DateElement value={from} />}</div>
}

const styles = {
    date: {
        margin: '0.35em 0',
        fontSize: '0.9em',
        color: 'var(--gray)',
    },
}
