import { useMemo } from 'react'

import useSWR from 'swr'
import { createFeatureCollection } from '@avalanche-canada/utils/products/mcr'

import client from 'clients/mcr'
import { useMapState } from 'contexts/map/state'

export const useMountainConditionsReport = id => {
    const { reports } = useMountainConditionsReports()

    // find the report within useMountainConditionsReports() (reports we've already downloaded)
    const report = reports.find(report => report.id === id)

    // if we don't have the report, download it
    const key = ['mcr', 'report', id]
    const { data } = useSWR(key, () => client.report(id))

    if (report) {
        return { report }
    }

    return { report: data }
}

export const useMountainConditionsReports = () => {
    const key = ['mcr', 'reports']

    const { data: reports } = useSWR(key, () => client.reports())

    const sortedReports = useMemo(() => {
        if (!reports) {
            return null
        }

        return reports.sort((a, b) => {
            return b.dates[0] > a.dates[0] ? 1 : -1
        })
    }, [reports])

    return {
        reports: sortedReports,
        features: useMemo(() => {
            if (!reports) {
                return null
            }

            return createFeatureCollection(reports)
        }, [reports]),
    }
}

export const useFilteredMountainConditionsReports = () => {
    const { reports, features } = useMountainConditionsReports()
    const { viewportBounds } = useMapState()

    return {
        reports: useMemo(() => {
            if (!reports) {
                return null
            }

            return geographicallyFilterReports(reports, viewportBounds.value)
        }, [reports, viewportBounds]),
        features: features,
    }
}

// Utils and Helpers
const geographicallyFilterReports = (mcr, bounds) => {
    if (!bounds || !bounds._sw || !bounds._ne || !bounds._sw || !bounds._ne) {
        return mcr
    }
    return mcr.filter(
        ({ location }) =>
            location[1] >= bounds._sw.lat &&
            location[0] >= bounds._sw.lng &&
            location[1] <= bounds._ne.lat &&
            location[0] <= bounds._ne.lng
    )
}
