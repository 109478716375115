import useSWR from 'swr'

import client from 'clients/spaws'
import { useLocale } from 'stores/SettingsStore'

export function useSpaw(id) {
    const locale = useLocale()
    const key = ['spaw', id, locale]

    return useSWR(key, () => client.product(id, locale))
}

export function useSpaws() {
    // TODO:: get local from setting
    const locale = useLocale()
    const key = ['spaw', locale]

    return useSWR(key, () => client.products(locale))
}

export function useSpawArea() {
    const locale = useLocale()
    const key = ['spaw', 'area', locale]
    const { data: spawArea } = useSWR(key, () => client.area(locale))

    return {
        spawArea,
    }
}
