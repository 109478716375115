import * as React from 'react'
import PropTypes from 'prop-types'

BannerSet.propTypes = {
    showTravelAdvice: PropTypes.bool,
    expandable: PropTypes.bool,
    children: PropTypes.node,
}

export default function BannerSet({ showTravelAdvice = false, expandable = false, children }) {
    const [value, set] = React.useState(null)

    children = React.Children.toArray(children).reverse()

    return (
        <g>
            {children.map((banner, index) =>
                React.cloneElement(banner, {
                    showTravelAdvice,
                    expandable,
                    expanded: value === index,
                    onExpandClick() {
                        set(value === index ? null : index)
                    },
                })
            )}
        </g>
    )
}
