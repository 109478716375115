import styles from './Panel.module.css'

type PanelProps = {
    header: React.ReactNode
    expanded?: boolean
    handleClick?: () => void
    children: React.ReactNode
}

export const Panel = ({ header, expanded = false, handleClick = () => {}, children }: PanelProps) => {
    return (
        <details open={expanded} className={styles.Container} onClick={handleClick}>
            <summary>{header}</summary>
            {children}
        </details>
    )
}
