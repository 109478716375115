import * as React from 'react'
import PropTypes from 'prop-types'

import { VerticalGallery } from 'components/gallery/VerticalGallery'

Image.propTypes = {
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
}

export default function Image({ url, alt }) {
    const image = [
        {
            url,
            caption: alt,
        },
    ]
    return (
        <div style={style.gallery}>
            <VerticalGallery images={image} />
        </div>
    )
}

const style = {
    gallery: {
        // Lazy way for the full-bleed effect
        marginLeft: 'calc(-1 * 1em)',
        marginRight: 'calc(-1 * 1em)',
    },
}
