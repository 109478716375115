import * as React from 'react'

import * as Generic from '@avalanche-canada/prismic/clients/generic'

import Forecasts from 'clients/forecasts'

export function Provider({ product, children }) {
    const value = React.useMemo(createValue, [product])
    function createValue() {
        return {
            product,
        }
    }

    return <context.Provider value={value}>{children}</context.Provider>
}

export async function get(id, locale) {
    const product = await Forecasts.product(id, locale)

    return { product }
}

export function getDocuments(locale) {
    return Generic.getEvery(uids, locale)
}

// Hook: aka getters
export function useForecast() {
    return useContextSlice('product')
}
// export function useDocuments() {
//     return useContextSlice('documents')
// }
// export function useDocument(uid) {
//     return useDocuments().get(uid)
// }
export function useReport() {
    const forecast = useForecast()

    return forecast?.report
}
export function useMedia() {
    const forecast = useForecast()

    return forecast?.media
}
export function useContext() {
    return React.useContext(context)
}
function useContextSlice(name) {
    return useContext()[name]
}

const context = React.createContext()
