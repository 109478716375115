import { FormattedMessage } from 'react-intl'

// Snowpack Section
export const OBSERVATION_TYPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Point or Summary Observation" />,
    path: 'observations.snowpack.obsType',
    options: [
        {
            key: 'point',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Point" />,
        },
        {
            key: 'summary',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Summary" />,
        },
    ],
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Is this a point observation or a summary of your day?"
            />
        ),
    },
}

export const ELEVATION = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Elevation (m) above sea level" />,
    path: 'observations.snowpack.siteElevation',
    min: 0,
    max: 8848,
}

export const ELEVATAION_BAND = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Elevation band" />,
    path: 'observations.snowpack.siteElevationBand',
    options: [
        {
            key: 'alp',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Alpine" />,
        },
        {
            key: 'tln',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Treeline" />,
        },
        {
            key: 'btl',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Below Treeline" />,
        },
    ],
}

export const ASPECT = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Aspect" />,
    path: 'observations.snowpack.siteAspect',
    options: [
        { key: 'N', label: <FormattedMessage description="MIN Form Field" defaultMessage="N" /> },
        { key: 'NE', label: <FormattedMessage description="MIN Form Field" defaultMessage="NE" /> },
        { key: 'E', label: <FormattedMessage description="MIN Form Field" defaultMessage="E" /> },
        { key: 'SE', label: <FormattedMessage description="MIN Form Field" defaultMessage="SE" /> },
        { key: 'S', label: <FormattedMessage description="MIN Form Field" defaultMessage="S" /> },
        { key: 'SW', label: <FormattedMessage description="MIN Form Field" defaultMessage="SW" /> },
        { key: 'W', label: <FormattedMessage description="MIN Form Field" defaultMessage="W" /> },
        { key: 'NW', label: <FormattedMessage description="MIN Form Field" defaultMessage="NW" /> },
    ],
}

export const SNOWPACK_DEPTH = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowpack depth (cm)" />,
    path: 'observations.snowpack.depth',
    min: 0,
    max: 10000,
}

export const WHUMPFING = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Did you observe whumpfing?" />,
    path: 'observations.snowpack.whumpfingObserved',
    options: [
        {
            key: true,
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Yes" />,
        }, // will need to convert to booleans
        {
            key: false,
            label: <FormattedMessage description="MIN Form Field" defaultMessage="No" />,
        },
    ],
    description: {
        title: <FormattedMessage description="MIN Form Field" defaultMessage="Whumpfing" />,
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="A whumpf is a rapid settlement of the snowpack caused by the collapse of a weak layer. It is accompanied by an audible noise."
            />
        ),
    },
}

export const CRACKING = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Did you observe cracking?" />,
    path: 'observations.snowpack.crackingObserved',
    options: [
        {
            key: true,
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Yes" />,
        }, // will need to convert to booleans
        {
            key: false,
            label: <FormattedMessage description="MIN Form Field" defaultMessage="No" />,
        },
    ],
    description: {
        title: <FormattedMessage description="MIN Form Field" defaultMessage="Cracking" />,
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Cracking is shooting cracks radiating more than a couple of metres from your sled or skis."
            />
        ),
    },
}

export const FOOT_PENETRATION = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Foot penetration (cm)" />,
    path: 'observations.snowpack.footPenetration',
    min: 0,
    max: 100,
    description: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="How far you sink into the snow when standing on one fully-weighted foot."
        />
    ),
}

export const SKI_PENETRATION = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Ski penetration (cm)" />,
    path: 'observations.snowpack.skiPenetration',
    min: 0,
    max: 200,
    description: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="How far you sink into the snow when standing on one fully-weighted ski."
        />
    ),
}

export const SLED_PENETRATION = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Sled penetration (cm)" />,
    path: 'observations.snowpack.sledPenetration',
    min: 0,
    max: 200,
    description: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="The depth a sled sinks into the snow after stopping slowly on level terrain."
        />
    ),
}

export const SNOWPACK_TEST_RESULT = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowpack test result" />,
    path: 'observations.snowpack.testInitiation',
    options: [
        {
            key: 'none',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="None" />,
        },
        {
            key: 'veryEasy',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Very easy" />,
        },
        {
            key: 'easy',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Easy" />,
        },
        {
            key: 'moderate',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Moderate" />,
        },
        {
            key: 'hard',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Hard" />,
        },
    ],
    description: (
        <FormattedMessage description="MIN Form Field" defaultMessage="Average if you did a number of tests." />
    ),
}

export const TEST_FRACTURE_CHARACTER = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowpack test fracture character" />,
    path: 'observations.snowpack.testFracture',
    options: [
        {
            key: 'sudden',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Sudden ('pop' or 'drop')" />,
        },
        {
            key: 'resistant',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Resistant" />,
        },
        {
            key: 'unevenBreak',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Uneven Break" />,
        },
    ],
}

export const TEST_FAILURE_DEPTH = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowpack test failure depth (cm)" />,
    path: 'observations.snowpack.testFailureDepth',
    min: 0,
    max: 200,
    description: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Depth below the surface that failure occurred."
        />
    ),
}

export const SURFACE_CONDITIONS = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Surface conditions" />,
    path: 'observations.snowpack.surfaceConditions',
    options: [
        {
            key: 'newSnow',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="New Snow" />,
        },
        {
            key: 'crust',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Crust" />,
        },
        {
            key: 'surfaceHoar',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Surface Hoar" />,
        },
        {
            key: 'facets',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Facets" />,
        },
        {
            key: 'corn',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Corn" />,
        },
        {
            key: 'variable',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Variable" />,
        },
    ],
}

export const TEST_FAILURE_CRYSTAL_TYPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowpack test failure layer crystal type" />,
    path: 'observations.snowpack.testFailureLayerCrystalTypes',
    options: [
        {
            key: 'surfaceHoar',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Surface Hoar" />,
        },
        {
            key: 'facets',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Facets" />,
        },
        {
            key: 'depthHoar',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Depth Hoar" />,
        },
        {
            key: 'stormSnow',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Storm snow" />,
        },
        {
            key: 'crust',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Crust" />,
        },
        {
            key: 'other',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Other" />,
        },
    ],
}

export const COMMENT = {
    path: 'observations.snowpack.comment',
}

export const SNOWPACK_FIELDS = [
    OBSERVATION_TYPE,
    ELEVATION,
    ELEVATAION_BAND,
    ASPECT,
    SNOWPACK_DEPTH,
    WHUMPFING,
    CRACKING,
    FOOT_PENETRATION,
    SKI_PENETRATION,
    SLED_PENETRATION,
    SNOWPACK_TEST_RESULT,
    TEST_FRACTURE_CHARACTER,
    TEST_FAILURE_DEPTH,
    SURFACE_CONDITIONS,
    TEST_FAILURE_CRYSTAL_TYPE,
    COMMENT,
]
