import { VerticalGallery } from 'components/gallery/VerticalGallery'
import * as Context from './Context'

export default function Gallery() {
    const { images } = Context.useReport()

    if (!Array.isArray(images) || images.length === 0) {
        return null
    }

    return <VerticalGallery images={images} />
}
