// import Image from './Image'
import SliceZone from './SliceZone'
import StructuredText from './StructuredText'
// import Embed from './Embed'
import Link from './Hyperlink'

// export { Image }
export { SliceZone }
export { StructuredText }
// export { Embed }
export { Link }
