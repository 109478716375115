import { get } from 'lodash'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Grid from '@mui/material/Grid'
import { Alert } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { isBefore } from 'date-fns'

import { ImageUpload } from 'components/MINReporting/ImageUpload'
import { Spacer } from 'components/Spacer/Spacer'
import { REPORT_TITLE, REPORT_DATE, LATITUDE, LONGITUDE, REPORT_IMAGES } from './constants/general'
import { InfoLabel } from './InfoLabel'
import Shim from 'components/Shim/Shim'
import { WarningLabel } from './WarningLabel'

export const BasicInfo = ({ updateReport, report, reportDescription }) => {
    return (
        <>
            <p style={styles.reportDescription}>{reportDescription}</p>
            <TextField
                onChange={event => updateReport(event.target.value, REPORT_TITLE.path)}
                value={get(report, REPORT_TITLE.path) || ''}
                fullWidth
                label={REPORT_TITLE.label}
                variant="outlined"
            />
            <Spacer />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label={REPORT_DATE.label}
                    value={get(report, REPORT_DATE.path) || null}
                    onChange={value => updateReport(value.toISOString(), REPORT_DATE.path)}
                    renderInput={params => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
            {get(report, REPORT_DATE.path) &&
                isBefore(new Date(get(report, REPORT_DATE.path)), new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) && (
                    <WarningLabel
                        info={
                            <FormattedMessage
                                defaultMessage="The date of this report is more than a week old. Is this correct?"
                                description="MIN Form Field"
                            />
                        }
                    />
                )}
            <Spacer />
            <Grid style={styles.grid} container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        value={get(report, LATITUDE.path) || ''}
                        disabled
                        fullWidth
                        label={LATITUDE.label}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={get(report, LONGITUDE.path) || ''}
                        disabled
                        fullWidth
                        label={LONGITUDE.label}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <InfoLabel
                info={
                    <FormattedMessage
                        defaultMessage="Use the map to enter lat/long coordinates. You may need to drag the drawer down to see the map."
                        description="MIN Form Field"
                    />
                }
            />
            <Spacer />
            {navigator.onLine === true ? (
                <ImageUpload
                    onChange={value => updateReport(value, REPORT_IMAGES.path)}
                    reportImages={get(report, REPORT_IMAGES.path)}
                />
            ) : (
                <Shim vertical>
                    <p style={styles.imageUploadText}>
                        <FormattedMessage description="MIN Form Images" defaultMessage="Images" />
                    </p>
                    <Alert severity="info">
                        <FormattedMessage
                            defaultMessage="You are offline. Images may be uploaded when you have a data connection."
                            description="MIN photo upload form"
                        />
                    </Alert>
                </Shim>
            )}
        </>
    )
}

const styles = {
    reportDescription: {
        color: 'var(--gray)',
        fontSize: '15px',
        marginBottom: '2rem',
    },
    imageUploadText: {
        fontSize: '12px',
        margin: '0px 1px 5px',
        color: 'var(--gray)',
    },
}
