import { useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'

import { FORECAST_CLOSED } from '@avalanche-canada/constants/products/mixpanel'

import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { useForecastHistory, useForecastId, useSetForecastHistory } from 'stores/MixpanelHistoryStore'

import styles from './CloseButton.module.css'

type CloseButtonProps = {
    callback: () => void
}

export const CloseButton = ({ callback }: CloseButtonProps) => {
    const sendTrackEvent = useSendTrackEvent()
    const forecastId = useForecastId()
    const forecastHistory = useForecastHistory()
    const setForecastHistory = useSetForecastHistory()

    const handleClick = useCallback(() => {
        sendTrackEvent(FORECAST_CLOSED, {
            forecastId,
            forecastHistory,
        })

        setForecastHistory([])

        callback()
    }, [callback, forecastId, forecastHistory, sendTrackEvent, setForecastHistory])

    return (
        <button className={styles.Button} onClick={handleClick}>
            <CloseIcon />
        </button>
    )
}
