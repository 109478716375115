import { FormattedMessage, FormattedNumber } from 'react-intl'
import { DateElement, DateTime } from 'components/time'
import Units from '@avalanche-canada/constants/intl/units'

export default function Value({ value, description = {} }) {
    const { format, unit, options } = description

    switch (typeof value) {
        case 'string':
            switch (format) {
                case 'date':
                    return <DateElement value={value} />
                case 'date-time':
                    return <DateTime value={value} />
                default:
                    if (options?.[value]) {
                        return options?.[value]
                    } else {
                        if (value.search('\n') !== -1) {
                            return value.split('\n').map((line, index) => <p key={index}>{line}</p>)
                        }

                        return value
                    }
            }
        case 'number': {
            if (Units.has(unit)) {
                return <FormattedNumber value={value} {...Units.get(unit)} />
            } else {
                return <FormattedNumber value={value} />
            }
        }
        case 'boolean':
            return value ? <FormattedMessage defaultMessage="Yes" /> : <FormattedMessage defaultMessage="No" />
        case 'object': {
            if (Array.isArray(value)) {
                // Using "Object.entries(options).map..." instead of "value.map..." to keep the same orders
                return (
                    <ul>
                        {Object.entries(options)
                            .filter(([name]) => value.includes(name))
                            .map(([value, display]) => (
                                <li key={value}>
                                    <Value description={description} value={display} />
                                </li>
                            ))}
                    </ul>
                )
            }
            break
        }
        default:
            return null
    }
}
