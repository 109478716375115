import PropTypes from 'prop-types'
import clsx from 'clsx'
import css from './Table.module.css'
import table from 'components/table/Table.module.css'

StationTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(PropTypes.object).isRequired,
    measurements: PropTypes.arrayOf(PropTypes.object),
    caption: PropTypes.string,
}

export default function StationTable({ columns, measurements, headers, caption }) {
    const bodies = measurements.reduce((groups, measurements) => {
        const { measurementDateDisplay } = measurements

        if (!groups.has(measurementDateDisplay)) {
            groups.set(measurementDateDisplay, [])
        }

        groups.get(measurementDateDisplay).push(measurements)

        return groups
    }, new Map())

    return (
        <div className={css.Container}>
            <div className={css.Content}>
                <table className={clsx(table.Condensed, table.Bordered)}>
                    <thead>
                        {headers.map((headers, index) => (
                            <tr key={index}>
                                <th />
                                {headers.map(({ title, name, property, ...header }) => (
                                    <th key={name} {...header}>
                                        {typeof title === 'function' ? title() : title}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {Array.from(bodies, ([title, measurements]) => (
                        <tbody key={title}>
                            <tr>
                                <th colSpan={columns.length}>{title}</th>
                            </tr>
                            {measurements.map((measurement, index) => (
                                <tr key={index}>{columns.map(renderRow, measurement)}</tr>
                            ))}
                        </tbody>
                    ))}
                    <caption>{caption}</caption>
                </table>
            </div>
        </div>
    )
}

// Utils
function renderRow({ property, name, ...props }, index) {
    if (index === 0) {
        return <th key={name}>{typeof property === 'function' ? property(this) : this[property]}</th>
    }

    return (
        <td key={name} {...props}>
            {typeof property === 'function' ? property(this) : this[property]}
        </td>
    )
}
