import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl'

export const ReportTypeControl = ({ value, setValue }) => {
    const options = [
        {
            label: <FormattedMessage description="MIN Report" defaultMessage="Quick" />,
            value: 'quick',
            ref: useRef(),
        },
        {
            label: <FormattedMessage description="MIN Report" defaultMessage="Avalanche" />,
            value: 'avalanche',
            ref: useRef(),
        },
        {
            label: <FormattedMessage description="MIN Report" defaultMessage="Snowpack" />,
            value: 'snowpack',
            ref: useRef(),
        },
        {
            label: <FormattedMessage description="MIN Report" defaultMessage="Weather" />,
            value: 'weather',
            ref: useRef(),
        },
        {
            label: <FormattedMessage description="MIN Report" defaultMessage="Incident" />,
            value: 'incident',
            ref: useRef(),
        },
    ]

    return (
        <SegmentedControl
            name="report-type"
            callback={val => setValue(val)}
            controlRef={useRef()}
            segments={options}
            active={value}
        />
    )
}
