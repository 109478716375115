import { WARNING } from 'constants/colors'
import { BsExclamationTriangle } from 'react-icons/bs'

export const WarningLabel = ({ info }) => {
    return (
        <div style={styles.warningContainer}>
            <BsExclamationTriangle style={styles.infoIcon} />
            <p style={styles.infoLabel}>{info}</p>
        </div>
    )
}

const styles = {
    warningContainer: {
        marginTop: 16,
        borderRadius: 4,
        padding: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: WARNING,
    },
    infoIcon: {
        color: 'black',
        marginRight: 8,
    },
    infoLabel: {
        color: 'black',
        fontSize: '0.8rem',
        margin: '0',
    },
}
