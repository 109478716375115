import React from 'react'

export const Label = ({ text, icon }) => {
    return (
        <div style={styles.labelContainer}>
            <div style={styles.labelBox}>
                <p style={styles.label}>{text}</p>
                <span style={styles.icon}>{icon}</span>
            </div>
            <div style={styles.labelBorder} />
        </div>
    )
}

const styles = {
    labelBorder: {
        height: '1px',
        width: '100%',
        backgroundColor: 'var(--gray)',
        position: 'absolute',
        top: '28px',
        zIndex: 1,
    },
    labelBox: {
        background: 'var(--background-color)',
        zIndex: 1000,
        position: 'absolute',
        paddingRight: '8px',
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        color: 'var(--gray)',
    },
    labelContainer: {
        position: 'relative',
        height: '25px',
        marginBottom: '20px',
    },
    icon: {
        color: 'var(--info)',
        margin: '19px 0px 0px 7px',
    },
}
