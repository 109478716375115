import { Children, cloneElement } from 'react'

export const OptionSet = ({ children, value, onChange }) => {
    return Children.map(children, (option, index) => {
        let active = false

        if (value instanceof Set) {
            active = value.has(option.props.value)
        } else {
            active = value === option.props.value
        }

        return cloneElement(option, {
            key: index,
            active,
            onClick(option) {
                if (value instanceof Set) {
                    const values = new Set(Array.from(value))

                    if (values.has(option)) {
                        values.delete(option)
                    } else {
                        values.add(option)
                    }

                    onChange(values)
                } else {
                    onChange(option)
                }
            },
        })
    })
}
