import PropTypes from 'prop-types'
import { InnerHTML } from 'components/misc'

Location.propTypes = {
    children: PropTypes.string.isRequired,
}

export default function Location({ location }) {
    return (
        <div style={styles.Location}>
            <p>{location}</p>
        </div>
    )
}

const styles = {
    LocationDescription: {
        margin: '0.35em 0',
        fontSize: '0.9em',
    },
}
