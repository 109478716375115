import { useMemo } from 'react'

import useSWR from 'swr'
import { get, getAll } from '@avalanche-canada/prismic/clients/accidents'
import { createFeatureCollection } from '@avalanche-canada/utils/products/accident'

import { useMapState } from 'contexts/map/state'
import { useLocale } from 'stores/SettingsStore'

export const useAccident = uid => {
    const { accidents } = useAccidents()

    // find the accident within useAccidents() (accidents we've already downloaded)
    let accident = accidents.find(accident => accident.uid === uid)

    // if we don't have the accident, download it
    const locale = useLocale()
    const key = ['accident', uid]
    const { data } = useSWR(key, () => get(uid, locale))

    if (accident) {
        return { accident }
    }

    return { accident: data }
}

export const useAccidents = () => {
    const key = ['accidents']

    const { data: accidents } = useSWR(key, () => getAll())

    return {
        accidents,
        features: useMemo(() => {
            if (!accidents) {
                return null
            }

            return createFeatureCollection(accidents)
        }, [accidents]),
    }
}

export const useFilteredAccidents = () => {
    const { accidents, features } = useAccidents()
    const { viewportBounds } = useMapState()

    return {
        accidents: useMemo(() => {
            if (!accidents) {
                return null
            }

            return geographicallyFilterAccidents(accidents, viewportBounds.value)
        }, [accidents, viewportBounds]),
        features: features,
    }
}

// Utils and Helpers
const geographicallyFilterAccidents = (accidents, bounds) => {
    if (!bounds || !bounds._sw || !bounds._ne || !bounds._sw || !bounds._ne) {
        return accidents
    }
    return accidents.filter(
        ({ data }) =>
            data.location.latitude >= bounds._sw.lat &&
            data.location.longitude >= bounds._sw.lng &&
            data.location.latitude <= bounds._ne.lat &&
            data.location.longitude <= bounds._ne.lng
    )
}
