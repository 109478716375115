import create from 'zustand'

const MapStore = create(set => {
    return {
        map: null,
        activeRegion: null,

        setMap: map => {
            set({ map })
        },
        setActiveRegion: activeRegion => {
            set({ activeRegion })
        },
    }
})

export const useMap = () => MapStore(state => state.map)
export const useSetMap = () => MapStore(state => state.setMap)
export const useActiveRegion = () => MapStore(state => state.activeRegion)
export const useSetActiveRegion = () => MapStore(state => state.setActiveRegion)
