import { useEffect } from 'react'

import { MIN_REPORT_OPENED } from '@avalanche-canada/constants/products/mixpanel'
import { FormattedMessage } from 'react-intl'

import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import Shim from 'components/Shim/Shim'
import { Sponsor } from 'components/Sponsor/Sponsor'
import { Share } from 'components/button/Share'
import { Header } from 'components/text/Header'
import { HeaderWithLocate } from 'components/text/HeaderWithLocate'
import * as Async from 'contexts/async'
import { useDisplaySchema, useSubmission } from 'hooks/data/MIN'
import * as Components from 'layouts/products/min'
import { captureException } from 'services/sentry'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'

export const MIN = ({ id }) => {
    const sendTrackEvent = useSendTrackEvent()

    useEffect(() => {
        sendTrackEvent(MIN_REPORT_OPENED, { id })
    }, [id])

    return (
        <DetailsWrapper>
            <Async.Provider value={useSubmission(id)}>
                <Async.Found>
                    <Content />
                </Async.Found>
                <Async.Offline />
            </Async.Provider>
        </DetailsWrapper>
    )
}

// Utils
export const Content = ({ payload }) => {
    const { displaySchemaId } = payload.schemaVersions
    const schema = useDisplaySchema(displaySchemaId)
    // Draft MINs don't have a location so we need to check for it
    const longitude = payload.location ? payload.location.longitude : undefined
    const latitude = payload.location ? payload.location.latitude : undefined
    const handleShareClick = async () => {
        try {
            await navigator.share({
                title: payload.title,
                url: generateMINShareURL(payload.submissionID),
            })
        } catch (error) {
            captureException(error)
        }
    }

    return (
        <Async.Provider value={schema}>
            <Async.Pending>
                <Shim horizontal>
                    <FormattedMessage description="MIN report view" defaultMessage="Loading report…" />
                </Shim>
            </Async.Pending>
            <Async.Found>
                <Components.Provider data={payload} schema={schema.data}>
                    {/* Draft MINs will have status 'draft=true' */}
                    {payload.draft ? (
                        <Header text={payload.title} />
                    ) : (
                        <HeaderWithLocate text={payload.title} location={[longitude, latitude]} />
                    )}
                    <Shim horizontal>
                        <Components.Metadata />
                    </Shim>
                    <Shim vertical>
                        <Components.ObservationsSet />
                    </Shim>
                    {!payload.draft && <Components.Gallery />}
                    <Shim horizontal vertical>
                        <div style={styles.flex}>
                            <Share handleShareClick={handleShareClick} />
                            <Sponsor type="MIN" />
                        </div>
                    </Shim>
                </Components.Provider>
            </Async.Found>
            <Async.Offline>
                <FormattedMessage
                    defaultMessage="You are offline. The MIN preview will work when you have a data connection."
                    description="MIN Review"
                />
            </Async.Offline>
        </Async.Provider>
    )
}

const styles = {
    flex: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
    },
}

const generateMINShareURL = id => {
    let urlPrefix = 'https://avalanche.ca/mountain-information-network/submissions/'

    if (process.env.NEXT_PUBLIC_AVCAN_API_URL === 'https://api-dev.avalanche.ca') {
        urlPrefix = 'https://devb.avalanche.ca/mountain-information-network/submissions/'
    }

    return urlPrefix + id
}
