import React from 'react'

import { FormattedMessage } from 'react-intl'
import { MdIosShare } from 'react-icons/md'
import { IoEllipsisVertical } from 'react-icons/io5'
import { Typography } from '@mui/material'

import { Muted } from 'components/text'

export const InstallationInstructions = () => {
    return (
        <div style={style.wrapper}>
            <div style={style.column}>
                <img src="https://assets.avalanche.ca/logo.svg" alt="Avalanche Canada" style={style.logo} />
                <Typography variant="h4" className="centred" style={style.heading}>
                    <FormattedMessage defaultMessage="Avalanche Canada" description="Installation instructions" />
                </Typography>
                <Typography variant="h5" className="centred">
                    <FormattedMessage defaultMessage="Mobile App" description="Installation Instructions" />
                </Typography>
                <Muted as="div" style={style.text}>
                    <p>
                        <FormattedMessage
                            defaultMessage="To use this app, please install it on your mobile device as follows:"
                            description="App instructions"
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                defaultMessage="iOS: in Safari, tap the"
                                description="App instructions iOS part 1 of 2"
                            />
                            <span style={style.icon}>
                                <MdIosShare />
                            </span>
                            <FormattedMessage
                                defaultMessage="icon then “Add to Home Screen”"
                                description="App instructions iOS part 2 of 2"
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                defaultMessage="Android: in Chrome, tap the"
                                description="App instructions Android part 1 of 2"
                            />
                            <span style={style.icon}>
                                <IoEllipsisVertical />
                            </span>
                            <FormattedMessage
                                defaultMessage="icon then “Install app”"
                                description="App instructions Android part 2 of 2"
                            />
                        </li>
                    </ul>
                </Muted>
            </div>
            <div style={style.backdrop}></div>
        </div>
    )
}

const style = {
    wrapper: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999999,
        pointerEvents: 'none',
        padding: '2em',
    },
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.04,
        background: 'url("/backdrop.svg") no-repeat bottom right',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 400,
    },
    logo: {
        width: 200,
        margin: '0 auto',
    },
    heading: {
        marginBottom: '0.25em',
    },
    text: {
        marginTop: '3em',
    },
    icon: {
        padding: '0 0.25rem',
    },
}
