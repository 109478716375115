import React from 'react'
import { useIntl } from 'react-intl'
import svg from './min/min-pin.svg'

export default function MountainInformationNetwork({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        id: 'icons.mountain-information-network',
        defaultMessage: 'Mountain Information Network icon',
        description: 'Icon for Mountain Information Network',
    })

    return <img width={width} height={height} alt={alt} {...props} src={svg.src} />
}
