import * as React from 'react'

import NextHead from 'next/head'
import { useIntl } from 'react-intl'
import {
    KANANASKIS,
    AVALANCHE_QUEBEC,
    PARKS_CANADA,
    PARKS_CANADA_BYK,
    PARKS_CANADA_GLACIER,
    PARKS_CANADA_JASPER,
    PARKS_CANADA_WATERTON,
} from '@avalanche-canada/constants/products/owners'

import { Provider, useForecast, useReport, get } from './Context'
import { ForecastMetadata, ForecastMetadataBottom } from './Metadata'
import Headline from './Headline'
import { TabSet } from './TabSet'
import Notifications from './notifications'
import * as Footers from './Footer'
import { handleForecastTabActivate } from 'services/analytics'

export { Provider, get, useForecast }
export { ForecastMetadata, ForecastMetadataBottom }
export { Headline }
export { TabSet }
export { Notifications }

const BaseFooter = Footers.default

export function Footer() {
    const forecast = useForecast()

    switch (forecast?.owner.value) {
        case AVALANCHE_QUEBEC:
            return (
                <BaseFooter>
                    <Footers.Prismic uid="areas-covered-bulletin" />
                    <Footers.DangerRatings />
                    <Footers.Disclaimer uid="forecast-disclaimer-avalanche-quebec" />
                </BaseFooter>
            )
        case KANANASKIS:
            return (
                <BaseFooter>
                    <Footers.DangerRatings />
                    <Footers.Disclaimer />
                </BaseFooter>
            )
        case PARKS_CANADA:
        case PARKS_CANADA_BYK:
        case PARKS_CANADA_GLACIER:
        case PARKS_CANADA_JASPER:
        case PARKS_CANADA_WATERTON:
            return (
                <BaseFooter>
                    <Footers.DangerRatings />
                    <Footers.Disclaimer uid="forecast-disclaimer-parks-canada" />
                </BaseFooter>
            )
        default:
            return <BaseFooter />
    }
}

export function Layout() {
    return (
        <React.Fragment>
            <Metadata />
            <Notifications />
            <Headline />
            <TabSet onTabChange={handleForecastTabActivate} />
        </React.Fragment>
    )
}

export function Head({ titleText }) {
    const intl = useIntl()
    const report = useReport()
    const title = titleText || report.title

    return (
        <NextHead>
            <title>
                {intl.formatMessage(
                    {
                        description: 'Avalanche Forecast page',
                        defaultMessage: '{title} - Avalanche Forecast',
                    },
                    { title }
                )}
            </title>
        </NextHead>
    )
}
