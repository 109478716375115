import React, { useState } from 'react'

import { Avatar, Box, Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useRouter } from 'next/router'

import { Back } from 'components/button/Back'
import Shim from 'components/Shim/Shim'
import { useAuth, useUsername, useUserAvatar, useLogin } from 'contexts/authentication'
import { UserMINSubmissions } from './UserMINSubmissions'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { SubHeader } from 'components/text/SubHeader'
import { Settings } from './Settings'
import { MIN } from 'components/Routing/MIN/MIN'
import { CloseButton } from 'components/BottomSheet/CloseButton/CloseButton'
import { Sponsor } from 'components/Sponsor/Sponsor'
import { EXPLORER_VIEW } from 'constants/layout'
import { setBottomSheetToMinHeight } from 'stores/BottomSheetStore'

export const Account = ({ setMINLayoutWithMIN, updateView, setForceShowTour }) => {
    const router = useRouter()
    const { isAuthenticated, logout, isLoading } = useAuth()
    const login = useLogin()
    const [selectedMIN, setSelectedMIN] = useState(null)
    const username = useUsername()
    const avatar = useUserAvatar()

    const handleLogin = () => {
        login()
    }

    const handleLogout = () => {
        logout({
            returnTo: window.location.origin,
        })
    }

    const handleTakeProductTour = () => {
        updateView(EXPLORER_VIEW)
        setBottomSheetToMinHeight()
        setForceShowTour(true)
    }

    return selectedMIN ? (
        <>
            <div style={style.flex}>
                <Sponsor type="MIN" />
                <CloseButton callback={() => router.back()} />
            </div>
            <MIN id={selectedMIN} />
        </>
    ) : (
        <DetailsWrapper>
            <Shim horizontal>
                <Box sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    <Back />
                </Box>
            </Shim>
            <Avatar sx={style.avatar} src={avatar} />
            <Shim horizontal vertical>
                {isAuthenticated ? (
                    <div style={style.centred}>
                        <FormattedMessage
                            description="Layout / MyAccount"
                            defaultMessage="Hi, {username}."
                            values={{ username }}
                        />
                    </div>
                ) : (
                    <FormattedMessage
                        defaultMessage="Login to your account to manage your MIN reports."
                        description="Account page"
                    />
                )}
            </Shim>
            <SubHeader>
                <FormattedMessage defaultMessage="Settings" description="Account page" />
            </SubHeader>
            <Settings />
            {isAuthenticated && (
                <UserMINSubmissions select={setSelectedMIN} setMINLayoutWithMIN={setMINLayoutWithMIN} />
            )}
            <Shim horizontal vertical>
                <div>
                    <Button
                        variant="outlined"
                        disabled={isLoading}
                        sx={style.authButton}
                        onClick={isAuthenticated ? handleLogout : handleLogin}
                    >
                        {isAuthenticated ? (
                            <FormattedMessage defaultMessage="Logout" description="Logout button" />
                        ) : (
                            <FormattedMessage defaultMessage="Login" description="Login button" />
                        )}
                    </Button>
                </div>
            </Shim>
            <Shim horizontal vertical>
                <div>
                    <Button variant="outlined" sx={style.authButton} onClick={handleTakeProductTour}>
                        <FormattedMessage defaultMessage="Take the tour" description="Take the tour button" />
                    </Button>
                </div>
            </Shim>
        </DetailsWrapper>
    )
}

const style = {
    centred: {
        textAlign: 'center',
    },
    avatar: {
        width: 100,
        height: 100,
        margin: 'auto',
    },
    authButton: {
        width: '100%',
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
}
