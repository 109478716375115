import PropTypes from 'prop-types'

import * as Types from '@avalanche-canada/prismic/types'
import * as URLUtils from '@avalanche-canada/utils/url'

DocumentLink.propTypes = {
    type: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    children: PropTypes.node,
    data: PropTypes.shape({
        title: PropTypes.string,
    }),
}

export default function DocumentLink({ children, type, uid, data }) {
    const href = pathname({ type, uid })

    return <a href={href}>{children || data?.title}</a>
}

function pathname({ type, uid }) {
    switch (type) {
        case Types.EVENT:
        case Types.BLOG:
        case Types.NEWS:
            return feed.uid(type, uid)
        case Types.DEFINITION:
            return `/glossary#${uid}`
        case Types.FATAL_ACCIDENT:
            return `/map?panel=fatal-accidents/${uid}`
        case Types.STATIC_PAGE:
            return URLUtils.buildPath('/pages', uid)
        default:
            return '/404'
    }
}
