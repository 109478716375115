import React, { useRef, useState } from 'react'

import { get } from 'lodash'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'

import { SnowConditionsForm } from './SnowConditionsForm'
import { IceConditionsForm } from './IceConditionsForm'
import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl'
import { Spacer } from 'components/Spacer/Spacer'
import { CheckboxGroup } from './CheckboxGroup'
import { MINCommentInput } from './MINCommentInput'
import { AVALANCHE_CONDITIONS, COMMENT, WEATHER } from './constants/quickReport'

export const QuickReport = ({ report, updateReport }) => {
    const [conditionType, setConditionType] = useState('snow')
    const options = [
        {
            label: <FormattedMessage description="MIN Report" defaultMessage="Snow Conditions" />,
            value: 'snow',
            ref: useRef(),
        },
        {
            label: <FormattedMessage description="MIN Report" defaultMessage="Ice Conditions" />,
            value: 'ice',
            ref: useRef(),
        },
    ]

    return (
        <>
            <SegmentedControl
                name="conditions-type"
                callback={val => setConditionType(val)}
                controlRef={useRef()}
                segments={options}
                active={conditionType}
            />
            {conditionType === 'snow' ? (
                <SnowConditionsForm report={report} updateReport={updateReport} />
            ) : (
                <IceConditionsForm report={report} updateReport={updateReport} />
            )}
            <Spacer />
            <CheckboxGroup
                options={WEATHER.options}
                label={WEATHER.label}
                selected={get(report, WEATHER.path) || []}
                onChange={value => updateReport(value, WEATHER.path)}
            />
            <Spacer />
            <CheckboxGroup
                options={AVALANCHE_CONDITIONS.options}
                label={AVALANCHE_CONDITIONS.label}
                selected={get(report, AVALANCHE_CONDITIONS.path) || []}
                onChange={value => updateReport(value, AVALANCHE_CONDITIONS.path)}
                singleRow
            />
            <Spacer />
            <MINCommentInput
                onChange={value => updateReport(value, COMMENT.path)}
                value={get(report, COMMENT.path) || ''}
            />
        </>
    )
}

const styles = {
    comment: {
        width: '100%',
        background: 'var(--background-color)',
    },
    previewButton: {
        display: 'block',
        margin: '0px auto 20px',
    },
}
