import { FormattedMessage } from 'react-intl'
import { List, ListItem as ListItemMUI, Box } from '@mui/material'

import messages from 'services/intl/messageDefinitions'
import { ListItem } from 'components/BottomSheet/ListItem/ListItem'
import { MountainConditionsReport } from 'components/icons'
import Shim from 'components/Shim/Shim'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Back } from 'components/button/Back'

import { useFilteredMountainConditionsReports } from 'hooks/data/MCR'

export const MCRTable = ({ select }) => {
    const { reports } = useFilteredMountainConditionsReports()

    return (
        <>
            <Shim horizontal>
                <Box sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    <Back />
                    <div>
                        <FormattedMessage {...messages.reportCount} values={{ count: reports.length }} />
                    </div>
                </Box>
            </Shim>
            <DetailsWrapper>
                <List>
                    {reports.length === 0 ? (
                        <ListItemMUI>
                            <FormattedMessage {...messages.listViewNoItems} />
                        </ListItemMUI>
                    ) : (
                        reports.map(item => {
                            return (
                                <ListItem
                                    key={item.id}
                                    Icon={MountainConditionsReport}
                                    select={select}
                                    id={item.id}
                                    title={item.title}
                                    datetime={item.dates[0]}
                                    username={item.user.name}
                                    region={item.location_desc}
                                />
                            )
                        })
                    )}
                </List>
            </DetailsWrapper>
        </>
    )
}
