import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import * as Hooks from 'hooks'
import css from './Credit.module.css'

Credit.propTypes = {
    children: PropTypes.string.isRequired,
    top: PropTypes.bool,
}

export default function Credit({ top, children }) {
    const [collapsed, toggleCollapsed] = Hooks.useToggle(false)
    const className = clsx(css.Container, {
        [css.Collapsed]: collapsed,
        [css.Top]: top,
    })

    return (
        <dl className={className} onClick={toggleCollapsed}>
            <dt className={css.Label}>
                <FormattedMessage defaultMessage="Credit" description="Component misc/Credit" />
            </dt>
            <dd className={css.Content}>{children}</dd>
        </dl>
    )
}
