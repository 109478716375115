import { Grid, Checkbox, FormControl, FormControlLabel } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Label } from 'components/MINReporting/Label'

export const CheckboxYesNo = ({ label, onChange, icon, value }) => {
    const handleClick = (whichCheckbox, value) => {
        if (value) {
            if (whichCheckbox === 'yes') {
                onChange(true)
            }
            if (whichCheckbox === 'no') {
                onChange(false)
            }
        } else {
            onChange(null)
        }
    }

    return (
        <FormControl fullWidth>
            <Label text={label} icon={icon} />
            <Grid style={styles.grid} container>
                <>
                    <Grid xs={6} item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value === true}
                                    onChange={event => handleClick('yes', event.target.checked)}
                                />
                            }
                            label={<FormattedMessage description="MIN Form Field" defaultMessage="Yes" />}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value === false}
                                    onChange={event => handleClick('no', event.target.checked)}
                                />
                            }
                            label={<FormattedMessage description="MIN Form Field" defaultMessage="No" />}
                        />
                    </Grid>
                </>
            </Grid>
        </FormControl>
    )
}

const styles = {
    grid: {
        marginTop: '5px',
    },
}
