import React from 'react'
import MyLocation from 'components/icons/MyLocation'

export const Locate = ({ onClick }) => {
    return (
        <button style={style.button} onClick={onClick}>
            <MyLocation />
        </button>
    )
}

const style = {
    button: {
        border: 'none',
        padding: 0,
        background: 'none',
    },
}
