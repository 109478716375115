import React from 'react'

import { BsInfoCircle } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'

export const InfoLabel = ({ info }) => {
    return (
        <div style={styles.infoContainer}>
            <BsInfoCircle style={styles.infoIcon} />
            <p style={styles.infoLabel}>{info}</p>
        </div>
    )
}

export const InfoLabelIncidentDescription = () => {
    return (
        <div style={styles.infoContainer}>
            <BsInfoCircle style={styles.infoIcon} />
            <p style={styles.infoLabel}>
                <FormattedMessage
                    defaultMessage="No names and no judging please. See <link>submission guidelines</link> for more details."
                    description="note underneath comment section in MIN Incident Form"
                    values={{
                        link(text) {
                            return (
                                <a
                                    href="https://www.avalanche.ca/mountain-information-network/submission-guidelines"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {text}
                                </a>
                            )
                        },
                    }}
                />
            </p>
        </div>
    )
}

const styles = {
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '35px',
    },
    infoIcon: {
        margin: '16px 5px 0 0 ',
        color: 'var(--gray)',
        fontSize: '0.8rem',
    },
    infoLabel: {
        color: 'var(--gray)',
        fontSize: '0.8rem',
    },
}
