import * as React from 'react'
import { get } from 'lodash'

const Context = React.createContext()

export function Provider({ children, data, schema }) {
    const value = React.useMemo(() => ({ data, schema }), [data, schema])

    return <Context.Provider value={value}>{children}</Context.Provider>
}

// Data
export function useReport() {
    const { data } = useContext()

    return data
}
export function useReportValue(name) {
    const report = useReport()

    return get(report, name)
}

// Schemas
export function useSchema() {
    const { schema } = useContext()

    return schema
}

// Utils
function useContext() {
    return React.useContext(Context)
}
