import * as React from 'react'

import * as Products from '@avalanche-canada/constants/products'
import { COLOUR_BLIND_PALETTE } from '@avalanche-canada/constants/products/forecast/ratings/colors'
import { iceClimbs } from '@avalanche-canada/constants/products/ice-climbs'
import * as ObservationTypes from '@avalanche-canada/constants/products/min/types'

import { useLayer as useLayerState } from 'contexts/layers'
import * as MapContext from 'contexts/map'
import { useMountainConditionsReports } from 'hooks/data/MCR'
import { useFilteredMINSubmissions } from 'hooks/data/MIN'
import { useSpawArea } from 'hooks/data/SPAW'
import { useAccidents } from 'hooks/data/accidents'
import { useForecasts } from 'hooks/data/forecasts'
import { useFilteredWeatherStations } from 'hooks/data/weather'
import { TILESETS } from 'services/mapbox/config'
import { useActiveRegion, useSetActiveRegion, useSetMap } from 'stores/MapStore'
import { useColorBlindMode } from 'stores/SettingsStore'
import MapStyles from './MapStyles'

export function ForecastAreas({ on }) {
    const key = Products.FORECAST
    const map = MapContext.useMap()
    const { areas, metadata } = useForecasts()
    const activeRegion = useActiveRegion()
    const setActiveRegion = useSetActiveRegion()

    const colourblindModeEnabled = useColorBlindMode()
    const setMap = useSetMap()

    // Inject metadata into each area's properties so that we can use them on the map
    const data = {
        ...areas,
        features: areas.features.map(area => {
            const specificMetaData = metadata.find(product => product.area.id === area.id)
            const { value: highestDanger } = specificMetaData.highestDanger
            const colour = colourblindModeEnabled
                ? COLOUR_BLIND_PALETTE.get(highestDanger)
                : specificMetaData.highestDanger.colour

            return {
                ...area,
                properties: {
                    ...area.properties,
                    colour,
                    id: specificMetaData.product.id,
                    highestDanger,
                },
            }
        }),
    }
    const { visible } = useLayerState(key)
    const layers = [
        createLayer(key, key, 'fill'),
        createLayer(key + '-line', key, 'line'),
        createLayer(key + '-labels', key, 'symbol'),
    ]

    // This is what adds the bolded outline around the forecast area when you tap it
    const handleClick = React.useCallback(
        e => {
            if (e.features.length > 0) {
                if (activeRegion !== null) {
                    map.setFeatureState(
                        {
                            source: key,
                            id: activeRegion,
                        },
                        { hover: false }
                    )
                }
                const newRegionToHover = e.features[0].properties.id
                setActiveRegion(newRegionToHover)
                map.setFeatureState(
                    {
                        source: key,
                        id: newRegionToHover,
                    },
                    { hover: true }
                )
                setMap(map)
                // setTooltipCoordinates(e.point)
            }
        },
        [activeRegion, key, map]
    )

    // Event handlers
    map.on('click', key, handleClick)

    return (
        <>
            <MapContext.Source id={key} data={data} generateId>
                {layers.map(layer => (
                    <MapContext.Layer key={layer.id} {...layer} visible={visible} on={on} />
                ))}
            </MapContext.Source>
        </>
    )
}

export function WeatherStations({ on }) {
    const key = Products.WEATHER_STATION
    const { visible } = useLayerState(key)
    const { features } = useFilteredWeatherStations()
    const layer = createLayer(key, key, 'symbol')

    return (
        <MapContext.Source id={key} data={features} cluster clusterProperties={ClusterProperties}>
            <MapContext.Layer {...layer} visible={visible} on={on} />
        </MapContext.Source>
    )
}

export function SPAW({ on }) {
    const key = Products.SPAW
    const { spawArea } = useSpawArea()
    // Inject SPAW product type into the properties, needed in the map click handler
    const productInjectedGeoJSON = {
        ...spawArea,
        features: spawArea.features.map(feature => {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    product: Products.SPAW,
                },
            }
        }),
    }

    const layers = [createLayer(key, key, 'fill'), createLayer(key + '-line', key, 'line')]

    return (
        <MapContext.Source id={key} data={productInjectedGeoJSON}>
            {layers.map(layer => (
                <MapContext.Layer key={layer.id} {...layer} on={on} />
            ))}
        </MapContext.Source>
    )
}

export function ClosureZones() {
    const key = Products.CLOSURE_ZONE
    const { visible } = useLayerState(key)
    const styles = MapStyles.get(key)

    return (
        <MapContext.VectorTileSource id={key} url={TILESETS.closureZones}>
            {Object.entries(styles).map(([type, style]) => (
                <MapContext.Layer
                    key={type}
                    id={key + '-' + type}
                    type={type}
                    visible={visible}
                    source-layer="Closure_Zones"
                    minzoom={type === 'fill' ? 6 : 8}
                    {...style}
                />
            ))}
        </MapContext.VectorTileSource>
    )
}

export function MountainConditionReports({ on }) {
    const key = Products.MOUNTAIN_CONDITIONS_REPORT
    const { visible } = useLayerState(key)
    const { features } = useMountainConditionsReports()
    const layer = { type: 'symbol', visible, ...MapStyles.get(key).symbol }
    const ids = [key, key + '-report']

    return (
        <React.Fragment>
            <MapContext.Source id={ids[0]} data={features} cluster clusterProperties={ClusterProperties}>
                <MapContext.Layer id={ids[0]} {...layer} on={on} />
            </MapContext.Source>
        </React.Fragment>
    )
}

export function FatalAccidents({ on }) {
    const key = Products.ACCIDENT
    const { visible } = useLayerState(key)
    const { features } = useAccidents()
    const layer = createLayer(key, key, 'symbol')

    return (
        <MapContext.Source id={key} data={features} cluster clusterProperties={ClusterProperties}>
            <MapContext.Layer {...layer} visible={visible} on={on} />
        </MapContext.Source>
    )
}

export function MountainInformationNetwork({ on }) {
    const key = Products.MOUNTAIN_INFORMATION_NETWORK
    const { visible } = useLayerState(key)
    const { features } = useFilteredMINSubmissions()
    const layer = { type: 'symbol', visible, ...MapStyles.get(key).symbol, on }

    return (
        <React.Fragment>
            <MapContext.Source id={key} data={features} cluster clusterProperties={MINClusterProperties}>
                <MapContext.Layer id={key} {...layer} />
            </MapContext.Source>
        </React.Fragment>
    )
}

export function IceClimbing({ on }) {
    const key = Products.ICE_CLIMB
    const { visible } = useLayerState(key)

    return (
        <React.Fragment>
            <MapContext.Source id={key} data={iceClimbs} cluster clusterProperties={ClusterProperties}>
                <MapContext.Layer id={key} type="symbol" visible={visible} {...MapStyles.get(key).symbol} on={on} />
            </MapContext.Source>
        </React.Fragment>
    )
}

// Utils and constants
function createLayer(id, source, type, styles = MapStyles.get(source)[type]) {
    return { id, source, type, ...styles }
}
const ClusterProperties = {
    product: ['string', ['get', 'product']],
}
const MINClusterProperties = {
    ...ClusterProperties,
    ...Object.values(ObservationTypes).reduce((properties, obtype) => {
        properties[obtype] = ['+', ['get', obtype]]

        return properties
    }, {}),
}
