import * as React from 'react'

import PropTypes from 'prop-types'
import * as utils from '@avalanche-canada/utils/fetch'
import { FormattedMessage } from 'react-intl'

import { Offline as OfflineText } from 'components/text'
import Shim from 'components/Shim/Shim'
// import * as Text from 'components/text'

// Context
const AsyncContext = React.createContext()

export const { Provider } = AsyncContext

// Components
Payload.propTypes = {
    children: PropTypes.node,
}

export function Payload({ children }) {
    return render(children, useData())
}

Pending.propTypes = {
    children: PropTypes.node,
}

export function Pending({
    children = <FormattedMessage defaultMessage="Loading…" description="General loading message" />,
}) {
    return usePending() === true ? children : null
}

Found.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]).isRequired,
}

export function Offline({ children = <OfflineText /> }) {
    if (!navigator.onLine) {
        return <Shim horizontal>{children}</Shim>
    }
}

export function Found({ children }) {
    const data = useData()

    if (!data) {
        return null
    }

    return render(children, data)
}

/*
    Case for Prismic document. 
    Prismic has no concept of NotFound error 
    or any other async hooks that use cached value
*/
Empty.propTypes = {
    children: PropTypes.node,
}

export function Empty({ children }) {
    const data = useData()
    const pending = usePending()
    const error = useError()
    const empty = !data && !pending && !error

    return empty ? children : null
}

// Error related components
Error.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

export function Error({ children }) {
    const error = useError()

    if (!error) {
        return null
    }

    if (React.isValidElement(children)) {
        children = React.cloneElement(children, { error })
    }

    return children
}

// TODO To be removed!
export function Errors({ children }) {
    const errors = useError()

    if (!Array.isArray(errors) || errors.length === 0) {
        return null
    }

    if (typeof children === 'function') {
        return children(errors)
    }

    if (React.isValidElement(children)) {
        return React.cloneElement(children, { errors })
    }

    return children
}

HTTPError.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    status: PropTypes.number,
}

export function HTTPError({ children, status }) {
    const error = useHTTPError()

    if (!error) {
        return null
    }

    if (React.isValidElement(children)) {
        children = React.cloneElement(children, { error })
    }

    if (typeof status === 'number') {
        return error.status === status ? children : null
    }

    return children
}

NotFound.propTypes = {
    children: PropTypes.node,
}

export function NotFound({ children }) {
    return <HTTPError status={404}>{children}</HTTPError>
}

export function Throw() {
    const error = useError()

    if (error) {
        throw error
    }

    return null
}

export function FirstError({ children }) {
    const error = useError()

    if (!error) {
        return null
    }
    return (
        React.Children.toArray(children).find(({ type }) => {
            switch (type) {
                case NotFound:
                    return error.status === 404
                case HTTPError:
                    return error instanceof utils.HTTPError
                case Error:
                    return error instanceof window.Error
                default:
                    return true
            }
        }) || null
    )
}

// Util hooks
function useData() {
    const { data } = useAsyncContext()

    return data
}
function usePending() {
    const { data, error } = useAsyncContext()

    return data === undefined && !error
}
function useError() {
    const { error } = useAsyncContext()

    return error
}
function useHTTPError() {
    const error = useError()

    return error instanceof utils.HTTPError ? error : null
}
function useAsyncContext() {
    return React.useContext(AsyncContext)
}
function render(children, payload) {
    if (typeof children === 'function') {
        return children(payload)
    }

    return React.isValidElement(children) ? React.cloneElement(children, { payload }) : children
}
