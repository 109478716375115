import { VerticalGallery } from 'components/gallery/VerticalGallery'

export default function Media({ images }) {
    const formattedImages = images.map((image, key) => {
        return {
            id: key,
            url: image,
        }
    })

    return <VerticalGallery images={formattedImages} />
}
