import React from 'react'
// import Section from './Section'
// import Ambassador from './Ambassador'
// import Gallery from './Gallery'
// import MailChimp from './MailChimp'
// import FeedSplash from './FeedSplash'
// import QuestionAnswer from './QuestionAnswer'
// import Quote from './Quote'
// import Slider from './Slider'
// import SponsorSet from './SponsorSet'
// import StaffSet from './StaffSet'
// import Tabular from './Tabular'
// import SidebarItemSet from './SidebarItemSet'
// import { Header } from 'components/sidebar'
import Block from './Block'
import Figure from './Figure'
import MultiColumnLayout from './MultiColumnLayout'
// import ContactForm from './ContactForm'

// These appear to be the minimum components required to render pages from the Ice Climbing Atlas

export default new Map([
    // ['section', Section],
    // ['ambassador', Ambassador],
    // ['gallery', Gallery],
    // ['mailChimp', MailChimp],
    // ['feedSplash', FeedSplash],
    // ['question-answer', QuestionAnswer],
    // ['quote', Quote],
    // ['slider', Slider],
    // ['sponsor-set', SponsorSet],
    // ['staff-set', StaffSet],
    // ['tabular', Tabular],
    // ['items', SidebarItemSet],
    // ['contact-form', ContactForm],
    // ['description_list', DescriptionList],
    ['block', Block],
    ['figure', Figure],
    ['multiColumnLayout', MultiColumnLayout],
])
