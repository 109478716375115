import PropTypes from 'prop-types'
import Components from './sections'

Observations.propTypes = {
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default function Observations({ content }) {
    return content.map(({ widget, ...item }) => {
        const Component = Components.get(widget)

        return <Component {...item} />
    })
}
