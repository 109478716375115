import { List, ListItem as ListItemMUI, Box, ListItemButton, ListItemText, Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import messages from 'services/intl/messageDefinitions'
import { useFilteredAccidents } from 'hooks/data/accidents'
import Shim from 'components/Shim/Shim'
import { FatalAccident } from 'components/icons'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Relative } from 'components/time'
import { Back } from 'components/button/Back'

export const AccidentsTable = ({ select }) => {
    const { accidents } = useFilteredAccidents()

    return (
        <>
            <Shim horizontal>
                <Box sx={style.headerSection}>
                    <Back />
                    <div>
                        <FormattedMessage
                            description="Accidents List View"
                            defaultMessage="{count, plural, one {# accident found} other {# accidents found}}"
                            values={{ count: accidents.length }}
                        />
                    </div>
                </Box>
            </Shim>
            <DetailsWrapper>
                <List>
                    {accidents.length === 0 ? (
                        <ListItemMUI>
                            <FormattedMessage {...messages.listViewNoItems} />
                        </ListItemMUI>
                    ) : (
                        accidents.map(item => {
                            return (
                                <ListItemMUI
                                    key={item.id}
                                    disablePadding
                                    onClick={() => {
                                        select(item.uid)
                                    }}
                                    sx={style.listItem}
                                >
                                    <ListItemButton>
                                        <ListItemText
                                            component="div"
                                            primary={
                                                <PrimaryText
                                                    // TODO: implement a more robust and translator safe version of this
                                                    title={item.data.title
                                                        .replace('Fatal Recreational Avalanche Incident', '')
                                                        .replace(item.data.dateOfAccident, '')}
                                                />
                                            }
                                            secondary={
                                                <SecondaryText
                                                    Icon={FatalAccident}
                                                    datetime={item.data.dateOfAccident}
                                                />
                                            }
                                        />
                                    </ListItemButton>
                                </ListItemMUI>
                            )
                        })
                    )}
                </List>
            </DetailsWrapper>
        </>
    )
}

const PrimaryText = ({ title }) => {
    return <Grid sx={style.truncateText}>{title}</Grid>
}

const SecondaryText = ({ Icon, datetime }) => {
    return (
        <div style={style.iconFlex}>
            <Icon width="16" height="16" />
            <span style={style.date}>
                {datetime + ' ('}
                <Relative value={datetime} style={{}} />
                {')'}
            </span>
        </div>
    )
}

const style = {
    headerSection: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
    iconFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    listItem: {
        borderTop: '1px solid var(--color-step-150)',
    },
    truncateText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: '0.3em',
    },
    date: {
        marginLeft: '0.3em',
    },
}
