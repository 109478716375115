import React, { createContext, useContext, useState, useMemo } from 'react'

import { List, ListItem as ListItemMUI, Pagination, IconButton, Snackbar, Alert, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { subDays } from 'date-fns'
import { captureException } from 'services/sentry'
import { FormattedMessage } from 'react-intl'
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list'

import messages from 'services/intl/messageDefinitions'
import * as Async from 'contexts/async'
import { useUserSubmissions, useDeleteSubmission, useUndoDeleteSubmission } from 'hooks/data/userMINs'
import client from 'clients/min'
import { Muted } from 'components/text'
import Shim from 'components/Shim/Shim'
import { MountainInformationNetwork } from 'components/icons'
import { SubHeader } from 'components/text/SubHeader'
import { ListItem } from 'components/BottomSheet/ListItem/ListItem'
import { AUTO_HIDE_DURATION, TOP_CENTER } from 'constants/snackbar'

const TablePageContext = createContext()

export const UserMINSubmissions = ({ select, setMINLayoutWithMIN }) => {
    const [page, setPage] = useState(1)

    const value = useMemo(() => ({ page, setPage }), [page])

    return (
        <TablePageContext.Provider value={value}>
            <SubHeader>
                <FormattedMessage defaultMessage="Mountain Information Network posts" description="User’s MIN table" />
            </SubHeader>
            <Shim horizontal>
                <Muted>
                    <FormattedMessage
                        defaultMessage="Here are your Mountain Information Network posts. Reports may be edited or deleted for up to two weeks following their initial post."
                        description="List of MIN posts"
                    />
                </Muted>
            </Shim>
            <Async.Provider value={useMySubmissions(page)}>
                <Async.Pending />
                <Async.Found>
                    <Content
                        pageChange={page => setPage(page)}
                        select={select}
                        setMINLayoutWithMIN={setMINLayoutWithMIN}
                    />
                </Async.Found>
            </Async.Provider>
        </TablePageContext.Provider>
    )
}

const Content = ({ payload, select, setMINLayoutWithMIN, ...props }) => {
    const [showNotification, setShowNotification] = useState(false)
    const [deletedId, setDeletedId] = useState(null)

    const { data } = payload.items
    const { itemCount, totalPages, page } = payload
    const actions = useActions()

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowNotification(false)
    }

    const handleDelete = (id, isEditable) => {
        if (!isEditable) {
            return
        }

        setShowNotification(true)
        setDeletedId(id)
        actions.delete(id)
    }

    const handleEdit = async (id, isEditable) => {
        if (!isEditable) {
            return
        }

        const reportData = await client.submission(id)

        if (!reportData) {
            // TODO: Handle this error
        }

        setMINLayoutWithMIN(reportData)
    }

    const handleUndoDelete = () => {
        actions.undoDelete(deletedId)
        setShowNotification(false)
        setDeletedId(null)
    }

    const trailingActions = ({ id, isEditable }) => (
        <TrailingActions>
            <SwipeAction onClick={() => handleEdit(id, isEditable)}>
                <div style={{ background: 'var(--brand-primary)', ...style.drawerButton }}>
                    <div style={{ ...style.icon, opacity: isEditable ? 1 : 0.3 }}>
                        <EditIcon />
                    </div>
                </div>
            </SwipeAction>

            <SwipeAction onClick={() => handleDelete(id, isEditable)} destructive={isEditable}>
                <div style={{ background: 'red', ...style.drawerButton }}>
                    <div style={{ ...style.icon, opacity: isEditable ? 1 : 0.5 }}>
                        <DeleteIcon />
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    )

    const action = (
        <>
            <Button color="inherit" size="small" onClick={handleUndoDelete}>
                <FormattedMessage defaultMessage="UNDO" description="Undo delete MIN report" />
            </Button>
        </>
    )

    function handlePageChange(page) {
        props.pageChange(page)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <>
            <Snackbar
                open={showNotification}
                autoHideDuration={AUTO_HIDE_DURATION}
                anchorOrigin={TOP_CENTER}
                onClose={handleCloseNotification}
            >
                <Alert action={action} severity="info" variant="filled">
                    <FormattedMessage
                        defaultMessage="Report deleted"
                        description="Notification snackbar for report deletion"
                    />
                </Alert>
            </Snackbar>
            <Shim horizontal>
                <FormattedMessage {...messages.reportCount} values={{ count: itemCount }} />
            </Shim>
            <SwipeableList>
                <List>
                    {data.length === 0 ? (
                        <ListItemMUI>
                            <FormattedMessage defaultMessage="No MIN posts" description="User MIN table" />
                        </ListItemMUI>
                    ) : (
                        data.map(item => {
                            const isEditable = new Date(item.datetime) > twoWeeksAgo
                            const { id } = item

                            return (
                                <SwipeableListItem
                                    key={id}
                                    listType={ListType.IOS}
                                    trailingActions={trailingActions({ id, isEditable })}
                                >
                                    <ListItem
                                        Icon={MountainInformationNetwork}
                                        select={select}
                                        id={id}
                                        title={item.title}
                                        datetime={item.datetime}
                                        username={item.username}
                                        region={item.region}
                                    />
                                </SwipeableListItem>
                            )
                        })
                    )}
                </List>
            </SwipeableList>
            {totalPages > 1 && (
                <Pagination count={totalPages} page={page} onChange={(e, page) => handlePageChange(page)} />
            )}
        </>
    )
}

const style = {
    drawerButton: {
        borderTop: '1px solid var(--color-step-150)',
        height: '100%',
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        color: 'white',
    },
}

// Helpers and constants

function useActions() {
    const deleteSubmission = useDeleteSubmission()
    const undoDeleteSubmission = useUndoDeleteSubmission()
    const { page } = useContext(TablePageContext)

    const { mutate } = useMySubmissions(page)
    return useMemo(
        () => ({
            async delete(id) {
                try {
                    await deleteSubmission(id)
                    mutate()
                } catch (error) {
                    captureException(error, { id })
                }
            },
            async undoDelete(id) {
                try {
                    await undoDeleteSubmission(id)
                    mutate()
                } catch (error) {
                    captureException(error, { id })
                }
            },
        }),
        [deleteSubmission, mutate, undoDeleteSubmission]
    )
}

const useMySubmissions = page => {
    const options = OPTIONS

    if (page) {
        options.page = page
    }

    return useUserSubmissions(options)
}

const twoWeeksAgo = subDays(new Date(), 14)
const OPTIONS = {
    pagesize: 30,
}
