import { FormattedMessage } from 'react-intl'
import { get, set, unset } from 'lodash'

import { REQUIRED_FIELDS } from './constants/general'
import { REPORT_TYPES, REPORT_TYPE_FIELDS } from './constants/general'
import { setBottomSheetToFullHeight } from 'stores/BottomSheetStore'

export const numberValidator = (value, min, max) => {
    if (value === '') {
        return true
    }
    const number = Number(value)
    return number >= min && number <= max
}

// Checks that all required fields are completed
export const isValidReport = reportData => {
    for (const field of REQUIRED_FIELDS) {
        if (!reportData[field]) return false
    }
    return true
}

// Used in the below function to check that a field is complete
export const fieldIsComplete = field => {
    if (Array.isArray(field)) {
        return field.length > 0
    } else {
        return field !== undefined && field !== ''
    }
}

// Calculate the progress in the bottom bar of the report form
export const calculateProgress = (reportData, reportType) => {
    let progress = 0

    // Give 5% for each basic info field
    if (reportData.title) {
        progress += 5
    }
    if (reportData.location?.latitude && reportData.location?.longitude) {
        progress += 5
    }
    if (reportData.datetime) {
        progress += 5
    }
    if (reportData.images) {
        progress += 5
    }

    // The other 80% is on a per section basis, so you could have 100% in a couple sections
    if (!reportData.observations || reportData.observations[reportType] === undefined) {
        return progress
    }

    const totalFields = REPORT_TYPE_FIELDS[reportType].length
    if (reportType === REPORT_TYPES.QUICK) {
        // Quick report is complicated because there are two subsections
        return progress
    } else {
        let totalFieldsCompleted = 0
        Object.keys(reportData.observations[reportType]).forEach(key => {
            if (fieldIsComplete(reportData.observations[reportType][key])) {
                totalFieldsCompleted += 1
            }
        })

        return (progress += (totalFieldsCompleted / totalFields) * 80)
    }
}

// Gets the lat/long when the user clicks on the map and adds it to the MIN form
export const handleMapClick = (event, setLocationUpdate) => {
    const { lngLat } = event
    const { lat, lng } = lngLat

    setBottomSheetToFullHeight()

    setLocationUpdate({ longitude: lng, latitude: lat })
}

// Determines if the field has an error
export const getError = (report, field, basePath) => {
    if (field.required) {
        return get(report, basePath) && !get(report, field.path)
    } else {
        return get(report, field.path) || get(report, field.path) === 0
            ? !numberValidator(get(report, field.path), field.min, field.max)
            : false
    }
}

// Provides helper text for the field if it is in error
export const HelperText = ({ report, field, basePath }) => {
    return (
        getError(report, field, basePath) &&
        (field.required ? (
            <FormattedMessage description="MIN Form Field helper text" defaultMessage="Required" />
        ) : (
            <FormattedMessage
                description="MIN Form Field helper text"
                defaultMessage="Value must be between {min} and {max}"
                values={{ min: field.min, max: field.max }}
            />
        ))
    )
}

// Outputs number fields that could have 0 as a value
export const outputPotentiallyZero = (report, path) => {
    return get(report, path) ? get(report, path) : get(report, path) === 0 ? 0 : ''
}

// Updates the report and allows users to delete values and put negative numbers in
export const numberHandler = (value, path, updateReport) => {
    if (value === '') {
        updateReport(null, path)
    } else if (value === '-') {
        updateReport('-', path)
    } else {
        const parsedValue = Number(value)
        if (!isNaN(parsedValue)) {
            updateReport(parsedValue, path)
        }
    }
}

// Used in the update report function
export const generateNewReportData = (reportData, value, path) => {
    if (value !== null && value !== undefined && value.length !== 0) {
        // It's a valid value - just update the data and return
        return set(reportData, path, value)
    }

    // If value is null, undefined, or an empty array then we want to unset the path
    unset(reportData, path)

    // If we are unsetting the last field in a section, we should unset the whole section
    const sectionPath = path.replace('.' + path.split('.').pop(), '')
    const sectionValue = get(reportData, sectionPath)

    // Iterate through the section and check if any fields are complete
    let isEmpty = true
    for (const key in sectionValue) {
        if (fieldIsComplete(sectionValue[key])) {
            isEmpty = false
            break
        }
    }
    if (isEmpty) {
        unset(reportData, sectionPath)
    }

    // If we are unsetting the last section in the observations, we should unset the whole section
    if (reportData.observations && Object.keys(reportData.observations).length === 0) {
        unset(reportData, 'observations')
    }

    return reportData
}
