import * as React from 'react'

import { DANGER_RATINGS_EXPLAINED_OPENED } from '@avalanche-canada/constants/products/mixpanel'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { VerticalGallery } from 'components/gallery/VerticalGallery'
import { Panel } from 'components/panel/Panel'
import Shim from 'components/Shim/Shim'
import { useDisclaimers } from 'hooks/data/disclaimers'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { StructuredText } from 'prismic/components/base'
import { useLocale } from 'stores/SettingsStore'
import { useForecast } from './Context'

import styles from './Forecast.module.css'

Footer.propTypes = {
    children: PropTypes.node,
}

export default function Footer({ children }) {
    return (
        <footer className={styles.Footer}>
            {children || (
                <React.Fragment>
                    <DangerRatings />
                    <Disclaimer />
                </React.Fragment>
            )}
        </footer>
    )
}

export function Disclaimer({ uid = 'forecast-disclaimer', expanded }) {
    return <Prismic uid={uid} expanded={expanded} />
}

export function Prismic({ uid, expanded }) {
    const { disclaimers } = useDisclaimers()
    const { data } = disclaimers.find(disclaimer => disclaimer.uid === uid)

    return (
        <Panel header={data.title} expanded={expanded}>
            <Shim horizontal>
                <StructuredText value={data.body} />
            </Shim>
        </Panel>
    )
}

export function DangerRatings({ expanded }) {
    const locale = useLocale()
    const forecast = useForecast()
    const sendTrackEvent = useSendTrackEvent()

    const images =
        locale === 'fr'
            ? [
                  {
                      url: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1701903639/website/NAPADS_fr.png',
                      height: 2338,
                      width: 3134,
                      caption: 'Échelle publique nord-américaine de danger d’avalanche',
                  },
              ]
            : [
                  {
                      url: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1702507147/website/NAPADS.png',
                      height: 2677,
                      width: 3727,
                      caption: 'North American Public Avalanche Danger Scale',
                  },
              ]

    const handleClick = () => {
        sendTrackEvent(DANGER_RATINGS_EXPLAINED_OPENED, {
            forecastId: forecast?.id,
        })
    }

    return (
        <Panel
            header={<FormattedMessage defaultMessage="Danger Ratings Explained" description="FX Footer" />}
            expanded={expanded}
            handleClick={handleClick}
        >
            <VerticalGallery images={images} cloudName="avalanche-ca" />
        </Panel>
    )
}
