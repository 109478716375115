import PropTypes from 'prop-types'
import { FormattedList } from 'react-intl'

Submitter.propTypes = {
    certification: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    groups: PropTypes.array,
}

export default function Submitter({ name, image = null, groups = [] }) {
    return (
        <div style={styles.container}>
            <img src={image} title={name} alt={name} style={styles.img} />
            <div style={styles.name}>
                <span>{name}</span>
                <span>
                    <FormattedList type="conjunction" value={groups.map(group => group.name)} />
                </span>
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        margin: '0.35em 0',
        fontSize: '0.9em',
        lineHeight: '1.5em',
    },
    img: {
        height: '35px',
    },
    name: {
        marginLeft: '1em',
        alignSelf: 'flex-start',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'Column',
        justifyContent: 'flex-start',
    },
}
