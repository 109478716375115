import useSWR from 'swr'

import { get } from '@avalanche-canada/prismic/clients/highlight'
import { useLocale } from 'stores/SettingsStore'

export function useHighlight() {
    const locale = useLocale()
    const key = ['highlight', locale]

    return useSWR(key, () => get(locale))
}
