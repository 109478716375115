import React from 'react'

import { Avatar as AvatarMUI } from '@mui/material'

export const Avatar = ({ callback, src }) => {
    return <AvatarMUI onClick={callback} sx={style.avatar} src={src} />
}

const style = {
    avatar: {
        marginTop: '3px', // Necessary here rather than using align-items "middle" on the parent as the parent's height changes when search terms are entered
    },
}
