import { useState, useEffect } from 'react'

// Via: https://github.com/andreyk1512/react-image-size
export function useImageSize(url) {
    const [dimensions, setDimensions] = useState(null)

    useEffect(() => {
        const fetch = async () => {
            setDimensions(null)

            try {
                const { width, height } = await getImageSize(url)

                setDimensions({ width, height })
            } catch (error) {
                console.error(error.toString())
            }
        }

        fetch()
    }, [url])

    return dimensions
}

// Helpers
const getImageSize = url => {
    return new Promise((resolve, reject) => {
        if (typeof window === 'undefined') {
            return reject('Window is not defined')
        }

        if (!url) {
            return reject('Url is not defined')
        }

        const img = new Image()

        img.addEventListener('load', () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight })
        })

        img.addEventListener('error', event => {
            reject(`${event.type}: ${event.message}`)
        })

        img.src = url
    })
}
