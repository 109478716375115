import React from 'react'
import { useIntl } from 'react-intl'
import fatalAccident from './fatal-accident.svg'

export default function FatalAccident({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        id: 'icons.fatal-accident',
        defaultMessage: 'Fatal Accdent icon',
        description: 'Icon for Fatal Accident',
    })

    return <img width={width} height={height} alt={alt} {...props} src={fatalAccident.src} />
}
