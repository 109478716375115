import React from 'react'
import { useRouter } from 'next/router'

import { CloseButton } from 'components/BottomSheet/CloseButton/CloseButton'
import { MINTable } from 'components/MIN/MINTable'
import { MIN } from 'components/Routing/MIN/MIN'
import { useGoToHome, useHistory } from 'stores/ViewStore'
import { MOUNTAIN_INFORMATION_NETWORK } from '@avalanche-canada/constants/products'

export const MINListView = ({ productID, setProduct }) => {
    const router = useRouter()

    const goToHome = useGoToHome()
    const history = useHistory()

    const handleXClick = () => {
        // get the previous view
        const { view, id } = history[history.length - 2]
        if (view === MOUNTAIN_INFORMATION_NETWORK && id === null) {
            // We came from the menu, go back to the menu
            router.back()
        } else {
            // We came from the map, go back to the main menu
            goToHome()
        }
    }

    return (
        <>
            {productID ? (
                <>
                    <CloseButton callback={handleXClick} />
                    <MIN id={productID} />
                </>
            ) : (
                <MINTable select={id => setProduct(id)} />
            )}
        </>
    )
}
