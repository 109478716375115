import {
    MOUNTAIN_CONDITIONS_REPORT,
    MOUNTAIN_INFORMATION_NETWORK,
    WEATHER_STATION,
    ACCIDENT,
    CLOSURE_ZONE,
    FORECAST,
    ICE_CLIMB,
} from '@avalanche-canada/constants/products'
import {
    MountainConditionsReport,
    MountainInformationNetwork,
    WeatherStation,
    FatalAccident,
    ClosureZone,
    Forecast,
    IceClimbingAtlas,
} from 'components/icons'

export const ICONS = new Map([
    [FORECAST, Forecast],
    [MOUNTAIN_CONDITIONS_REPORT, MountainConditionsReport],
    [MOUNTAIN_INFORMATION_NETWORK, MountainInformationNetwork],
    [WEATHER_STATION, WeatherStation],
    [ACCIDENT, FatalAccident],
    [CLOSURE_ZONE, ClosureZone],
    [ICE_CLIMB, IceClimbingAtlas],
])
