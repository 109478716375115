import React from 'react'

import { useIntl } from 'react-intl'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import clsx from 'clsx'

import { useLayer } from 'contexts/layers'
import * as Layers from 'constants/products/layers'
import { ICONS } from 'constants/icons'
import styles from './LayerMenu.module.css'

export const LayerOption = ({ id }) => {
    const intl = useIntl()
    const { visible, toggle } = useLayer(id)
    const title = intl.formatMessage(Layers.TitleMessages[id])
    const Icon = ICONS.get(id)

    return (
        <ListItem disablePadding>
            <ListItemButton
                onClick={toggle}
                className={clsx({
                    [styles.Inactive]: !visible,
                })}
            >
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText primary={title} />
            </ListItemButton>
        </ListItem>
    )
}
