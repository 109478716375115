import { useIntl } from 'react-intl'
import forecast from './Forecast.svg'

export default function Forecast({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        defaultMessage: 'Danger Rating Icon',
    })

    return <img width={width} height={height} alt={alt} {...props} src={forecast.src} />
}
