import React from 'react'

import { ListItem as ListItemMUI, ListItemText, ListItemButton, Grid } from '@mui/material'
import { FormattedNumber } from 'react-intl'

import { WeatherStation } from 'components/icons'

export const WeatherStationListItem = ({ title, source, elevation, onClick }) => {
    return (
        <ListItemMUI sx={style.listItem} disablePadding onClick={onClick}>
            <ListItemButton>
                <ListItemText
                    component="div"
                    primary={<PrimaryText Icon={WeatherStation} title={title} source={source} />}
                    secondary={
                        <FormattedNumber
                            description="Component weather/station/Metadata"
                            value={elevation}
                            // eslint-disable-next-line react/style-prop-object
                            style="unit"
                            unit="meter"
                            unitDisplay="long"
                        />
                    }
                />
            </ListItemButton>
        </ListItemMUI>
    )
}

const PrimaryText = ({ Icon, title, source }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} sx={style.truncateText}>
                {title}
            </Grid>
            <Grid item xs={6} sx={{ ...style.iconFlex, justifyContent: 'flex-end' }}>
                <Icon width="16" height="16" />
                <span style={style.truncateText}>{source}</span>
            </Grid>
        </Grid>
    )
}

const style = {
    iconFlex: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.3em',
    },
    listItem: {
        borderTop: '1px solid var(--color-step-150)',
    },
    truncateText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}
