import { useMemo } from 'react'

import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { useIntl } from 'react-intl'
import { createFeatureCollection } from '@avalanche-canada/utils/products/weather/stations'
import * as Formats from '@avalanche-canada/constants/intl/formats'

import { useMapState } from 'contexts/map/state'
import client from 'clients/weather'

export function useStation(id) {
    const key = createKey(id)

    return useSWRImmutable(key, () => client.station(id))
}

export function useWeatherStations() {
    const key = createKey()
    const { data: stations } = useSWRImmutable(key, () => client.stations())

    return {
        stations,
        features: useMemo(() => {
            if (!stations) {
                return null
            }
            return createFeatureCollection(stations)
        }, [stations]),
    }
}

export const useFilteredWeatherStations = () => {
    const { stations, features } = useWeatherStations()
    const { viewportBounds } = useMapState()

    return {
        stations: useMemo(() => {
            if (!stations) {
                return null
            }

            return geographicallyFilterStations(stations, viewportBounds.value)
        }, [stations, viewportBounds]),
        features: features,
    }
}

export function useMeasurements(stationId) {
    const key = createKey(stationId, 'measurements')
    const { data: station } = useStation(stationId)
    const swr = useSWR(key, () => client.measurements(stationId))
    const { data: measurements } = swr
    const intl = useIntl()

    return {
        ...swr,
        data: useMemo(() => {
            if (!measurements || !station) {
                return
            }

            const { timezone: timeZone } = station
            const formatDate = { ...Formats.DATE, timeZone }
            const formatTime = { ...Formats.TIME, timeZone }
            const formatDateTime = { ...Formats.DATETIME, timeZone }
            function createMeasurements(measurements) {
                const dateTime = new Date(measurements.measurementDateTime)

                return {
                    ...measurements,
                    measurementDateTime: dateTime,
                    measurementDateDisplay: intl.formatDate(dateTime, formatDate),
                    measurementTimeDisplay: intl.formatDate(dateTime, formatTime),
                    measurementDateTimeDisplay: intl.formatDate(dateTime, formatDateTime),
                }
            }
            function sorter(a, b) {
                // Please note: b - a to make it descendant, ie. newest measurements first
                return b.measurementDateTime - a.measurementDateTime
            }

            return measurements.map(createMeasurements).sort(sorter)
        }, [measurements, station, intl]),
    }
}

// Utils
function createKey(...items) {
    return ['stations', ...items]
}

const geographicallyFilterStations = (stations, bounds) => {
    if (!bounds || !bounds._sw || !bounds._ne || !bounds._sw || !bounds._ne) {
        return stations
    }

    return stations.filter(
        ({ latitude, longitude }) =>
            latitude >= bounds._sw.lat &&
            longitude >= bounds._sw.lng &&
            latitude <= bounds._ne.lat &&
            longitude <= bounds._ne.lng
    )
}
